import { useQuery } from "@tanstack/react-query";

import { MODEL_LAKE_URL } from "@/assets/constants/constants";
import { QUERY_KEYS } from "@/assets/constants/query-keys";
import {
  availableVoicesArray,
  type AvailableVoicesSchema,
  type VoiceLanguageCodes,
} from "@/types/schemas/api/text2speech";
import { validateZodSchema } from "@/utils";

import { getRequest, postRequest } from "./utils";

export const getAvailableVoices = async (languageCode: VoiceLanguageCodes): Promise<AvailableVoicesSchema[]> =>
  validateZodSchema(
    getRequest(`${MODEL_LAKE_URL}/text-to-speech/available-voice-configs/${languageCode}`),
    availableVoicesArray,
  );

export const useAvailableVoices = (languageCode: VoiceLanguageCodes) => {
  return useQuery({
    queryFn: () => getAvailableVoices(languageCode),
    queryKey: [QUERY_KEYS.AVAILABLE_VOICES, languageCode],
    staleTime: Infinity,
    gcTime: Infinity,
  });
};

export const fetchAudioStream = async (
  payload: {
    paragraphSummaryId: string;
    voiceConfig: string;
  },
  abortSignal?: AbortSignal,
): Promise<ReadableStream<Uint8Array>> => {
  return postRequest(`${MODEL_LAKE_URL}/text-to-speech/paragraph-summary/`, payload, abortSignal);
};
