import { useEffect } from "react";

import { useDebounce } from "usehooks-ts";

import { useAvailableVoices } from "@/api/text2speech";
import { speeds, speedValues } from "@/assets/constants/tts";
import {
  CheckMarkIcon,
  PlayerArrowIcon,
  PlayerPlayIcon,
  PlayerStopIcon,
  PlayerVoiceChangeIcon,
  ReadAloudIcon,
} from "@/components/icons/summary";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { PlayerProgress } from "@/components/ui/progress";
import { type SummaryResponse } from "@/types/schemas";
import { VoiceLanguageCodes } from "@/types/schemas/api/text2speech.ts";
import { cn } from "@/utils";
import useAppStateStore from "@/zustand/store";

const CustomSelectItem = ({
  title,
  items,
  state,
  setState,
}: {
  title: string;
  items: string[];
  state: string;
  setState: (value: string) => void;
}) => {
  return (
    <div className="my-4 flex flex-col items-start justify-center gap-1">
      <h4 className="p-2 pb-4 font-jakarta text-xs font-bold text-secondary-onBg">{title}</h4>
      {items.map((item) => (
        <div
          className="flex cursor-pointer items-center justify-start gap-3.5 p-2"
          key={item}
          onClick={() => setState(item)}
        >
          <CheckMarkIcon className={cn(state !== item && "hidden")} />
          <div className={cn("h-4 w-4", state === item && "hidden")} />
          <p className={cn("font-jakarta text-label-l3-regular text-text-active", state === item && "font-bold")}>
            {item}
          </p>
        </div>
      ))}
    </div>
  );
};

export const ReadAloudPopover = ({ summary }: { summary: SummaryResponse }) => {
  const {
    play,
    setPlay,
    toggleSpeaker,
    toggleSpeed,
    prevDisabled,
    nextDisabled,
    selectedChapterId,
    selectedParagraphId,
    selectedVoice,
    setSelectedVoice,
    selectedPlaybackSpeed,
    setSelectedPlaybackSpeed,
    open,
    setOpen,
    handleSpeakerClick,
    handleSpeedClick,
    previousChapterClick,
    nextChapterClick,
    setParagraphDetails,
    setSelectedChapterId,
    setSelectedChapterIdIndex,
    setSelectedParagraphId,
    setPrevDisabled,
    setNextDisabled,
  } = useAppStateStore((state) => ({
    play: state.play,
    setPlay: state.setPlay,
    toggleSpeaker: state.toggleSpeaker,
    toggleSpeed: state.toggleSpeed,
    prevDisabled: state.prevDisabled,
    setPrevDisabled: state.setPrevDisabled,
    nextDisabled: state.nextDisabled,
    setNextDisabled: state.setNextDisabled,
    selectedChapterId: state.selectedChapterId,
    setSelectedChapterId: state.setSelectedChapterId,
    setSelectedChapterIdIndex: state.setSelectedChapterIdIndex,
    selectedParagraphId: state.selectedParagraphId,
    setSelectedParagraphId: state.setSelectedParagraphId,
    selectedVoice: state.selectedVoice,
    setSelectedVoice: state.setSelectedVoice,
    selectedPlaybackSpeed: state.selectedPlaybackSpeed,
    setSelectedPlaybackSpeed: state.setSelectedPlaybackSpeed,
    open: state.open,
    setOpen: state.setOpen,
    handleSpeakerClick: state.handleSpeakerClick,
    handleSpeedClick: state.handleSpeedClick,
    previousChapterClick: state.previousChapterClick,
    nextChapterClick: state.nextChapterClick,
    setParagraphDetails: state.setParagraphDetails,
  }));

  const { data: voices } = useAvailableVoices(VoiceLanguageCodes.EN);

  const openDebounced = useDebounce(open, 150);

  useEffect(() => {
    if (summary && summary.chapterIdOrderedList.length > 0) {
      const initialChapterId = summary.chapterIdOrderedList[0];

      if (!selectedChapterId || !selectedParagraphId) {
        setSelectedChapterId(initialChapterId);
        setSelectedChapterIdIndex(0);
        const initialParagraphId = summary?.paragraphSummariesOfChapter[initialChapterId]?.[0];

        setSelectedParagraphId(initialParagraphId);
      }

      setPrevDisabled(false);
      setNextDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDebounced]);

  return (
    <Popover open={openDebounced} onOpenChange={() => setOpen(!open, summary)}>
      <PopoverTrigger asChild>
        <section className="h-12 w-12">
          <Button
            className="h-12 w-12 rounded-none p-4"
            disabled={summary?.chapterIdOrderedList.length < 1}
            size="icon"
            variant="icon"
          >
            <ReadAloudIcon />
          </Button>
        </section>
      </PopoverTrigger>
      <PopoverContent className="z-50 p-2">
        <div>
          <div className="justify-betweeen flex items-center gap-8">
            <Button
              className="h-7 w-7 rounded-lg p-1.5 hover:bg-container-tertiary"
              disabled={prevDisabled}
              size="icon"
              variant="icon"
              onClick={() => previousChapterClick(summary)}
            >
              <PlayerArrowIcon />
            </Button>
            <p className="w-[170px] overflow-x-hidden truncate whitespace-nowrap text-center font-jakarta text-xs font-bold text-text-active">
              {summary ? summary.chapterIdNamePairs[selectedChapterId]?.chapterName : "Initial chapter name"}
            </p>
            <Button
              className="h-7 w-7 rounded-lg p-1.5 hover:bg-container-tertiary"
              disabled={nextDisabled}
              size="icon"
              variant="icon"
              onClick={() => nextChapterClick(summary)}
            >
              <PlayerArrowIcon className="rotate-180" />
            </Button>
          </div>
          {toggleSpeaker && voices && (
            <div>
              <CustomSelectItem
                items={voices}
                setState={setSelectedVoice}
                state={selectedVoice}
                title="Select speaker"
              />
            </div>
          )}
          {toggleSpeed && (
            <div>
              <CustomSelectItem
                items={speeds}
                setState={setSelectedPlaybackSpeed}
                state={selectedPlaybackSpeed}
                title="Change playback speed"
              />
            </div>
          )}
          {!toggleSpeaker && !toggleSpeed && (
            <div className="mx-auto flex w-[234px] gap-px px-2 py-4">
              {summary?.paragraphSummariesOfChapter[selectedChapterId]?.map((paragraphId) => (
                <PlayerProgress
                  className="cursor-pointer"
                  key={paragraphId}
                  value={paragraphId === selectedParagraphId ? 100 : 0}
                  onClick={() => setParagraphDetails(paragraphId)}
                />
              ))}
            </div>
          )}
          <div className="flex items-center justify-between gap-6 px-[29px]">
            <Button
              className={cn(
                "h-10 w-10 rounded-lg p-2 hover:bg-container-tertiary",
                toggleSpeaker && "bg-primitive-purple-200",
              )}
              size="icon"
              variant="icon"
              onClick={handleSpeakerClick}
            >
              <PlayerVoiceChangeIcon />
            </Button>
            <button
              className="flex items-center justify-center rounded-full bg-default p-3 hover:bg-primitive-purple-800"
              onClick={() => setPlay(!play)}
            >
              {play ? <PlayerPlayIcon /> : <PlayerStopIcon />}
            </button>
            <Button
              className={cn(
                "h-10 w-10 rounded-lg p-2 font-jakarta text-label-l4 hover:bg-container-tertiary",
                toggleSpeed && "bg-primitive-purple-200",
              )}
              size="icon"
              variant="icon"
              onClick={handleSpeedClick}
            >
              {speedValues[selectedPlaybackSpeed]}x
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
