const InfoCard = ({ icon, title, value }: { icon: JSX.Element; title: string; value?: string | null | undefined }) => {
  return (
    <div className="flex w-[265.33px] items-center gap-3 rounded-lg border border-stroke-secondary-onBg p-3">
      {icon}
      <div className="flex flex-col">
        <h5 className="text-sm font-bold text-primary-onBg" data-cy="title">
          {title}
        </h5>
        <p className="line-clamp-1 text-sm capitalize text-secondary-onBg" data-cy="text">
          {value === null || value === undefined || value === "" ? "Not specified" : value}
        </p>
      </div>
    </div>
  );
};

export default InfoCard;
