import React, { useEffect } from "react";

import { useIsFreeUser } from "@/service/hooks/settings.ts";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  client?: string;
  slot: string;
  layout?: string;
  layoutKey?: string;
  format?: string;
  responsive?: string;
  pageLevelAds?: boolean;
  adTest?: string;
  children?: React.ReactNode;
}

const testMode = process.env.NODE_ENV === "development" || process.env.VITE_APP_ENV === "STAGING" ? "on" : undefined;
const testBg = testMode ? "bg-gray-300" : undefined;

function MaskedNameForFunnyStuffInternal({
  className = testBg,
  style = { display: "block" },
  client = "ca-pub-7893224341925415",
  slot,
  layout = "",
  layoutKey = "",
  format = "auto",
  responsive = "false",
  pageLevelAds = false,
  adTest = testMode,
  children,
  ...rest
}: Props) {
  useEffect(() => {
    const p: {
      google_ad_client?: string;
      enable_page_level_ads?: boolean;
    } = {};

    if (pageLevelAds) {
      p.google_ad_client = client;
      p.enable_page_level_ads = true;
    }

    try {
      if (typeof window === "object") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push(p);
      }
    } catch {
      // Pass
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ins
      className={`adsbygoogle ${className}`}
      data-ad-client={client}
      data-ad-format={format}
      data-ad-layout={layout}
      data-ad-layout-key={layoutKey}
      data-ad-slot={slot}
      data-adtest={adTest}
      data-full-width-responsive={responsive}
      style={style}
      {...rest}
    >
      {children}
    </ins>
  );
}
export const MaskedNameForFunnyStuff = (props: Props) => {
  const isFreeUser = useIsFreeUser();

  if (!isFreeUser) return null;

  return <MaskedNameForFunnyStuffInternal {...props} />;
};
