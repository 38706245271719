import { useMemo } from "react";

import { CircleCheckBig } from "lucide-react";

import { summarizationStatus } from "@/assets/constants/constants.ts";
import PreSummarizationView from "@/components/document/summary/pre-summarization-view.tsx";
import { getSummaryPercentage } from "@/components/document/summary/utils.tsx";
import { MaskedNameForFunnyStuff } from "@/components/funny-stuff/google.tsx";
import { InfiniteLoading } from "@/pages/loading";
import { useIsFreeUser } from "@/service/hooks/settings.ts";
import { useIsSummaryUsedUp } from "@/service/subscription";
import {
  type DocumentProgress,
  type DocumentSummaryRedisTicker,
  type SummaryResponse,
  type SubscriptionResponse,
  type DocumentEntity,
} from "@/types/schemas";

const NotSummarizedState = ({
  error,
  document,
  subscription,
  documentProcessingProgress,
  summary,
  summaryProgress,
  isAiAllowed,
}: {
  error?: Response;
  document?: DocumentEntity;
  subscription?: SubscriptionResponse;
  documentProcessingProgress?: DocumentProgress;
  summary?: SummaryResponse;
  summaryProgress?: DocumentSummaryRedisTicker;
  isAiAllowed: boolean;
}) => {
  const isUsedUp = useIsSummaryUsedUp();
  const isFreeUser = useIsFreeUser();
  const actionZone = useMemo(() => {
    const underProcessingAndPaidUser =
      documentProcessingProgress?.processing &&
      (document?.documentDetails.processingStatus === "UNDER_PROCESSING" ||
        document?.documentDetails.processingStatus === "NOT_PROCESSED") &&
      subscription?.subscriptionPlan !== "free";
    const underSummarization = summary?.summarizationStatus === summarizationStatus.enum.UNDER_SUMMARIZATION;

    const canSummarize =
      document?.documentDetails.processingStatus === "PROCESSED" &&
      isAiAllowed &&
      !isUsedUp &&
      summaryProgress === undefined &&
      summary?.summarizationStatus === summarizationStatus.enum.UNSUMMARIZED;

    const progressValue = Math.round(getSummaryPercentage(summaryProgress));
    const value = progressValue === 100 ? <CircleCheckBig /> : `${progressValue}%`;

    const getView = () => {
      if (underSummarization && isFreeUser)
        return (
          <section className="flex h-full w-full items-center justify-center">
            <section className="h-[250px] w-[300px]">
              <MaskedNameForFunnyStuff
                format="inline"
                responsive={undefined}
                slot="6125924297"
                style={{
                  display: "inline-block",
                  width: "300px",
                  height: "250px",
                }}
              />
            </section>
          </section>
        );
      if (underSummarization)
        return (
          <div className="flex flex-col items-center justify-center gap-4">
            <section className="relative flex flex-row items-center gap-2">
              <InfiniteLoading className="h-20 w-20" />
              <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">{value}</span>
            </section>
            <p className="mb-4 w-2/3 text-center text-base font-normal text-secondary-onBg">
              Your summary is being generated, please wait a moment...
            </p>
          </div>
        );
      if (error && error.status === 403) return <PreSummarizationView mode="OUT_OF_SUMMARY" />;
      if (isUsedUp) return <PreSummarizationView mode="OUT_OF_SUMMARY" />;
      if (
        (canSummarize || underProcessingAndPaidUser) &&
        !error &&
        !isUsedUp &&
        summary?.resummarizationEligible === true
      )
        return <PreSummarizationView mode="RE_SUMMARIZE_FOR_FREE" />;

      return <PreSummarizationView mode="EMPTY_STATE" />;
    };

    return (
      <section className="flew-row flex justify-center animate-in fade-in-40 slide-in-from-left-1">{getView()}</section>
    );
  }, [
    error,
    summary?.resummarizationEligible,
    documentProcessingProgress?.processing,
    document?.documentDetails.processingStatus,
    isAiAllowed,
    summaryProgress,
    subscription?.subscriptionPlan,
    summary?.summarizationStatus,
    isUsedUp,
  ]);

  return <section className="mx-auto flex max-w-2xl flex-1 flex-col justify-center">{actionZone}</section>;
};

export default NotSummarizedState;
