import { useCallback, useMemo } from "react";

import { useCopyToNote } from "@/api/ai-function.ts";
import {
  useDocumentSummaryParagraph,
  useExtendParagraphSummary,
  useUpdateParagraphSummary,
} from "@/api/document-summary.ts";
import { QuinoTooltip } from "@/components/generic";
import {
  AddToNoteIcon,
  DislikeIcon,
  DislikeIconFilled,
  ExpandAnswerIcon,
  HighlightIcon,
  LikeIcon,
  LikeIconFilled,
  ListenIcon,
} from "@/components/icons/summary";
import { Button } from "@/components/ui/button";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Skeleton } from "@/components/ui/skeleton";
import { useIsDemoLikePage, useIsTemporaryPage } from "@/service/hooks/misc.ts";
import { useCurrentWorkspaceId, useNavigateToDocumentPage } from "@/service/hooks/react-router";
import { useMixpanelTrack } from "@/service/mixpanel";
import { LikeDislikeValue, type SummaryResponse } from "@/types/schemas";
import { useCurrentProjectId, useStringifiedFeedbackSummary } from "@/utils";
import { QuinoMarkdownSummary } from "@/utils/util-components.tsx";
import useAppStateStore from "@/zustand/store";

interface ParagraphSummaryProps {
  paramDocumentId: string;
  paragraphId: string;
  summary: SummaryResponse;
  chapterId: string;
}

function ParagraphSkeleton(): JSX.Element {
  return (
    <section className="flex w-full flex-col gap-2">
      <Skeleton className="h-4" />
      <Skeleton className="h-4 w-11/12" />
      <Skeleton className="h-4" />
      <Skeleton className="h-4 w-7/12" />
    </section>
  );
}

const ParagraphSummary = ({ paragraphId, paramDocumentId, summary, chapterId }: ParagraphSummaryProps) => {
  //state
  const { playSelectedParagraph } = useAppStateStore((state) => ({
    playSelectedParagraph: state.playSelectedParagraph,
  }));
  const paragraph = useDocumentSummaryParagraph(paramDocumentId, paragraphId);
  const feedback = useStringifiedFeedbackSummary(paragraph?.userLikeDislikeValue);
  const { matched: isDemo } = useIsDemoLikePage();
  const mixpanelTrack = useMixpanelTrack();
  const { matched: isTemporary } = useIsTemporaryPage();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const currentProjectId = useCurrentProjectId();
  //navigation
  const navigateToDocumentPage = useNavigateToDocumentPage();

  //api interactions
  const copyToNotes = useCopyToNote();
  const updateParagraphSummary = useUpdateParagraphSummary();
  const { mutate: extendParagraphSummary, isPending } = useExtendParagraphSummary();

  const executeRatingSummary = useCallback(
    (value: LikeDislikeValue) => {
      if (!paragraph) return;
      if (isDemo)
        mixpanelTrack("docu_summ_paragraph_feedback", {
          feedback_value: value,
        });
      updateParagraphSummary.mutate({
        documentId: paramDocumentId,
        paragraphId: paragraph.id,
        paragraphSummaryId: paragraph.id,
        projectId: currentProjectId,
        workspaceId: currentWorkspaceId,
        payload: {
          likeDislikeValue: value,
        },
      });
    },
    [currentProjectId, currentWorkspaceId, isDemo, mixpanelTrack, paragraph, paramDocumentId, updateParagraphSummary],
  );
  const executeHighlight = useCallback(
    (value: boolean) => {
      if (!paragraph) return;
      if (isDemo)
        mixpanelTrack("docu_summ_paragraph_highlight", {
          highlighted: value,
        });
      updateParagraphSummary.mutate({
        documentId: paramDocumentId,
        paragraphId: paragraph.id,
        paragraphSummaryId: paragraph.id,
        projectId: currentProjectId,
        workspaceId: currentWorkspaceId,
        payload: {
          highlighted: value,
        },
      });
    },
    [currentProjectId, currentWorkspaceId, isDemo, mixpanelTrack, paragraph, paramDocumentId, updateParagraphSummary],
  );

  const executeCopy = useCallback(() => {
    mixpanelTrack("docu_summ_add_paragraph_to_note_click");

    copyToNotes.mutate({
      text: paragraph?.generatedSummary ?? "",
    });
  }, [copyToNotes, mixpanelTrack, paragraph?.generatedSummary]);

  const executeNavigation = useCallback(() => {
    mixpanelTrack("paragraph_summary_backlink_click");
    navigateToDocumentPage(paramDocumentId ?? "", paragraph?.pageNumbers.at(0) ?? 1, paragraph?.generatedSummary ?? "");
  }, [mixpanelTrack, navigateToDocumentPage, paragraph, paramDocumentId]);

  //display
  const content = useMemo(
    () =>
      isPending ? <ParagraphSkeleton /> : <QuinoMarkdownSummary>{paragraph?.generatedSummary}</QuinoMarkdownSummary>,
    [isPending, paragraph?.generatedSummary],
  );

  return (
    <HoverCard closeDelay={200} openDelay={0}>
      <HoverCardTrigger asChild>
        <section
          className="rounded-lg border border-transparent px-2 py-1.5 animate-in fade-in-40 slide-in-from-left-1 hover:border-stroke-primary-onBg data-[highlighted=true]:bg-primitive-yellow-200"
          data-highlighted={paragraph?.highlighted}
          onClick={executeNavigation}
        >
          {content}
        </section>
      </HoverCardTrigger>
      <HoverCardContent
        hideWhenDetached
        avoidCollisions={false}
        className=" z-10 flex h-10 w-auto flex-row items-center rounded-xl border border-modal-stroke p-1.5 data-[temporary=true]:hidden"
        data-temporary={isTemporary}
        side="top"
        sideOffset={10}
        // style={{
        //   zIndex: 0,
        // }}
      >
        <section className="flex h-[30px] min-w-[431px] items-center justify-between gap-0.5 bg-white">
          <Button
            className="flex h-7 justify-between gap-1 whitespace-nowrap rounded-lg px-2 py-1.5 font-jakarta text-label-l4 text-text-link-active-onBg hover:bg-container-tertiary"
            variant="icon"
            onClick={executeCopy}
          >
            <AddToNoteIcon />
            Add to notes
          </Button>
          <Button
            className="flex h-7 justify-between gap-1 whitespace-nowrap rounded-lg px-2 py-1.5 font-jakarta text-label-l4 text-text-link-active-onBg hover:bg-container-tertiary"
            disabled={paragraph?.expanded}
            variant="icon"
            onClick={() => extendParagraphSummary({ paragraphId: paragraphId, documentId: paramDocumentId })}
          >
            <ExpandAnswerIcon />
            Expand answer
          </Button>
          <Button
            className="flex h-7 justify-between gap-1 whitespace-nowrap rounded-lg px-2 py-1.5 font-jakarta text-label-l4 text-text-link-active-onBg hover:bg-container-tertiary"
            variant="icon"
            onClick={() => executeHighlight(!paragraph?.highlighted)}
          >
            <HighlightIcon />
            {paragraph?.highlighted ? "Remove highlight" : "Highlight"}
          </Button>
          <Button
            className="flex h-7 justify-between gap-1 whitespace-nowrap rounded-lg px-2 py-1.5 font-jakarta text-label-l4 text-text-link-active-onBg hover:bg-container-tertiary"
            variant="icon"
            onClick={() => playSelectedParagraph(chapterId, paragraphId, summary)}
          >
            <ListenIcon />
            Listen
          </Button>
          <div className="m-2 h-4 w-px self-stretch border border-modal-stroke bg-white" />
          <section className="flex flex-row items-center">
            <QuinoTooltip align="center" className="font-semibold" content={"Like"} side="bottom">
              <Button
                className="h-7 w-7 gap-1 rounded-lg p-1.5 hover:bg-container-tertiary data-[status=false]:px-5"
                data-status={paragraph?.likeDislikeValue && paragraph.likeDislikeValue <= 0}
                size="xs"
                variant="icon"
                onClick={() =>
                  executeRatingSummary(
                    paragraph?.userLikeDislikeValue === LikeDislikeValue.UPVOTE
                      ? LikeDislikeValue.NEUTRAL
                      : LikeDislikeValue.UPVOTE,
                  )
                }
              >
                <span className="h-4 w-4">
                  <LikeIcon
                    className="hidden h-4 w-4 data-[status=DISLIKE]:block data-[status=EMPTY]:block"
                    data-status={feedback}
                  />
                  <LikeIconFilled
                    className="hidden h-4 w-4 fill-white data-[status=LIKE]:block"
                    data-status={feedback}
                  />
                </span>
                <section
                  className="hidden w-[0.5rem] text-xs font-bold text-default data-[status=false]:block"
                  data-status={paragraph?.likeDislikeValue && paragraph.likeDislikeValue <= 0}
                >
                  {(paragraph?.likeDislikeValue ?? 0) <= 0 ? null : paragraph?.likeDislikeValue}
                </section>
              </Button>
            </QuinoTooltip>
          </section>
          <section className="flex flex-row items-center">
            <QuinoTooltip align="center" className="font-semibold" content={"Dislike"} side="bottom">
              <Button
                className="h-7 w-7 rounded-lg p-1.5 hover:bg-container-tertiary"
                size="icon"
                variant="icon"
                onClick={() =>
                  executeRatingSummary(
                    paragraph?.userLikeDislikeValue === LikeDislikeValue.DOWNVOTE
                      ? LikeDislikeValue.NEUTRAL
                      : LikeDislikeValue.DOWNVOTE,
                  )
                }
              >
                <DislikeIcon
                  className="hidden h-4 w-4 data-[status=EMPTY]:block data-[status=LIKE]:block"
                  data-status={feedback}
                />
                <DislikeIconFilled
                  className="hidden h-[0.875rem] w-[0.875rem] data-[status=DISLIKE]:block"
                  data-status={feedback}
                />
              </Button>
            </QuinoTooltip>
          </section>
        </section>
      </HoverCardContent>
    </HoverCard>
  );
};

export default ParagraphSummary;
