import { type StateCreator } from "zustand";

import { type AppSlice, type Text2SpeechSlice } from "@/types/app.ts";
import { type SummaryResponse } from "@/types/schemas";

const createText2SpeechSlice: StateCreator<AppSlice, [["zustand/devtools", never]], [], Text2SpeechSlice> = (
  set,
  get,
) => ({
  play: false,
  setPlay: (value: boolean) => set({ play: value }, false, "setPlay"),
  toggleSpeaker: false,
  setToggleSpeaker: (value: boolean) => set({ toggleSpeaker: value }, false, "setToggleSpeaker"),
  toggleSpeed: false,
  setToggleSpeed: (value: boolean) => set({ toggleSpeed: value }, false, "setToggleSpeed"),
  prevDisabled: false,
  setPrevDisabled: (value: boolean) => set({ prevDisabled: value }, false, "setPrevDisabled"),
  nextDisabled: false,
  setNextDisabled: (value: boolean) => set({ nextDisabled: value }, false, "setNextDisabled"),
  selectedChapterId: "",
  setSelectedChapterId: (value: string) => set({ selectedChapterId: value }, false, "setSelectedChapterId"),
  selectedChapterIdIndex: 0,
  setSelectedChapterIdIndex: (value: number) =>
    set({ selectedChapterIdIndex: value }, false, "setSelectedChapterIdIndex"),
  selectedParagraphId: "",
  setSelectedParagraphId: (value: string) => set({ selectedParagraphId: value }, false, "setSelectedParagraphId"),
  selectedVoice: "DEFAULT",
  setSelectedVoice: (value: string) => set({ selectedVoice: value }, false, "setSelectedVoice"),
  selectedPlaybackSpeed: "Normal 1.0x",
  setSelectedPlaybackSpeed: (value: string) => set({ selectedPlaybackSpeed: value }, false, "setSelectedPlaybackSpeed"),
  open: false,
  setOpen: (value: boolean, summary: SummaryResponse) => {
    if (summary.chapterIdOrderedList.length < 1) {
      set({ open: false }, false, "setOpen");
    } else {
      set({ open: value }, false, "setOpen");
    }
  },
  ttsLoading: false,
  setTTSLoading: (ttsLoading) => set({ ttsLoading }),
  handleSpeakerClick: () =>
    set({ toggleSpeed: false, toggleSpeaker: !get().toggleSpeaker }, false, "handleSpeakerClick"),
  handleSpeedClick: () => set({ toggleSpeaker: false, toggleSpeed: !get().toggleSpeed }, false, "handleSpeedClick"),
  previousChapterClick: (summary: SummaryResponse) => {
    const { selectedChapterIdIndex, setPrevDisabled } = get();
    if (selectedChapterIdIndex > 0) {
      const newIndex = selectedChapterIdIndex - 1;
      const newChapterId = summary.chapterIdOrderedList[newIndex];
      set(
        {
          selectedChapterIdIndex: newIndex,
          selectedChapterId: newChapterId,
          selectedParagraphId: summary.paragraphSummariesOfChapter[newChapterId][0],
          prevDisabled: newIndex <= 0,
          nextDisabled: false,
        },
        false,
        "previousChapterClick",
      );
    } else {
      setPrevDisabled(true);
    }
  },
  nextChapterClick: (summary: SummaryResponse) => {
    const {
      selectedChapterIdIndex,

      setNextDisabled,
    } = get();
    if (selectedChapterIdIndex < summary.chapterIdOrderedList.length - 1) {
      const newIndex = selectedChapterIdIndex + 1;
      const newChapterId = summary.chapterIdOrderedList[newIndex];

      set(
        {
          selectedChapterIdIndex: newIndex,
          selectedChapterId: newChapterId,
          selectedParagraphId: summary.paragraphSummariesOfChapter[newChapterId][0],
          nextDisabled: newIndex >= summary.chapterIdOrderedList.length - 1,
          prevDisabled: false,
        },
        false,
        "nextChapterClick",
      );
    } else {
      setNextDisabled(true);
    }
  },
  setParagraphDetails: (paragraphId: string) => {
    const { setSelectedParagraphId } = get();
    setSelectedParagraphId(paragraphId);
  },
  playSelectedParagraph: (chapterId: string, paragraphId: string, summary: SummaryResponse) => {
    set(
      {
        selectedChapterId: chapterId,
        selectedChapterIdIndex: summary.chapterIdOrderedList.indexOf(chapterId),
        selectedParagraphId: paragraphId,
        open: true,
        play: true,
      },
      false,
      "playSelectedParagraph",
    );
  },
});

export default createText2SpeechSlice;
