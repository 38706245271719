import { useMemo } from "react";

import { ModalKeys } from "@/assets/constants/modal.ts";
import { MaskedNameForFunnyStuff } from "@/components/funny-stuff/google.tsx";
import { QuinoTooltip } from "@/components/generic";
import { UploadDocumentIcon } from "@/components/icons";
import { Button } from "@/components/ui/button.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { LinkToLibrary, ProjectSelectorAccordion, WorkspaceSelectorAccordion } from "@/components/v3";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const WorkspacePanel = () => {
  const [, setOpen] = useModalState(ModalKeys.DOCUMENT_UPLOAD_OPEN);
  return useMemo(() => {
    return (
      <section className="flex h-full w-full flex-col rounded-2xl border border-stroke-default shadow-document-view-panel">
        <WorkspaceSelectorAccordion />
        <section className="flex-1 overflow-hidden">
          <section className="h-full overflow-y-auto">
            <ProjectSelectorAccordion />
          </section>
        </section>
        <section className="w-full overflow-hidden">
          <MaskedNameForFunnyStuff
            format="inline"
            responsive="true"
            slot="2565953233"
            style={{ display: "inline-block", height: "184px", width: "100%" }}
          />
        </section>
        <section className="flex flex-row items-center border-t border-stroke-default">
          <Button
            className="flex h-full flex-1 cursor-pointer flex-row items-center justify-center gap-1 rounded-none border-r border-stroke-default text-center"
            variant="ghost"
            onClick={() => setOpen({ isOpen: true })}
          >
            <span className="h-4 w-4">
              <UploadDocumentIcon className="h-4 w-4 fill-primary" />
            </span>
            <Typography asChild className="overflow-hidden text-xs font-bold text-default">
              <span className="line-clamp-1">Upload file</span>
            </Typography>
          </Button>
          <section className="h-12">
            <QuinoTooltip align="center" className="z-30 font-semibold" content="Open library" side="top">
              <span>
                <LinkToLibrary />
              </span>
            </QuinoTooltip>
          </section>
        </section>
      </section>
    );
  }, [setOpen]);
};

export default WorkspacePanel;
