export const QUERY_KEYS = {
  PLAN_DETAILS: "PLAN_DETAILS",
  STRIPE_PLANS: "STRIPE_PLANS",
  STRIPE_PLAN_FEATURES: "STRIPE_PLAN_FEATURES",
  CHECK_IF_OLD_SUBSCRIPTION: "CHECK_IF_OLD_SUBSCRIPTION",
  FAVOURITE_ELEMENTS: "FAVOURITE_ELEMENTS",
  SHARED_ELEMENTS: "SHARED_ELEMENTS",
  USER_ONBOARDING_DATA: "USER_ONBOARDING_DATA",
  NOTE_IDS: "NOTE_IDS",
  WORKSPACES: "WORKSPACES",
  WORKSPACES_IDS: "WORKSPACES_IDS",
  NOTE: "NOTE",
  PROJECTS: "PROJECTS",
  MY_PROJECTS: "MY_PROJECTS",
  UNIVERSITY_DATA: "university-data",
  FIREBASE_USER_DATA: "firebase-user-data",
  MY_SUBSCRIPTION: "my-subscription",
  CHAT_HISTORY_COMPLETE: "chat-history-all",
  CHAT_SESSION: "chat-session",
  CHAT_ELEMENT: "chat-element",
  RESOURCE: "resource",
  DOCUMENT: "document",
  FOLDER_FILES: "folder-files",
  FOLDER_FOLDERS: "folder-folders",
  PROJECT_FOLDERS: "project-folders",
  AVAILABLE_VOICES: "AVAILABLE_VOICES",
};
