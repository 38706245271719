import { type Dispatch, type SetStateAction, useEffect, useMemo, useCallback, useState } from "react";

import _ from "lodash";
import mixpanel from "mixpanel-browser";
import { useIdToken } from "react-firebase-hooks/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { useAvailableModels } from "@/api/ai-function.ts";
import { useAddDocumentToStore } from "@/api/resources.ts";
import { useClaimPreregFDS, useCountryIs } from "@/api/user.ts";
import { useWorkspaces } from "@/api/workspace.ts";
import { ResourceCardType, ROUTES } from "@/assets/constants/constants.ts";
import { DISABLED_COUNTRY_TAGS, PRESERVED_QUERY_PARAMS } from "@/assets/constants/user.ts";
import { loadingClaimToast } from "@/components/toast";
import { toast } from "@/components/ui/use-toast.ts";
import { auth } from "@/firebase";
import { useAppAuthState, useFirebaseUserId, useUpdateFirebaseUserData, useUserPublicData } from "@/firebase/hooks.ts";
import { useIsDemoLikePage, useTypographyConfig } from "@/service/hooks/misc.ts";
import { useMixpanelTrack } from "@/service/mixpanel";
import type { FontScale } from "@/types/app.ts";
import { SUPPORTED_LANGUAGES, SUPPORTED_LANGUAGES_LABELS } from "@/types/interfaces/library.tsx";

export const useIsCountryAllowed = () => {
  const { data, isLoading, isError } = useCountryIs();
  return useMemo(() => {
    if (process.env.NODE_ENV === "development") return true;
    if (isError) return false;
    if (isLoading || !data) return null;
    return !DISABLED_COUNTRY_TAGS.includes(data.country);
  }, [data, isError, isLoading]);
};

export const usePreservedQueryParams = () => {
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    const values = PRESERVED_QUERY_PARAMS.filter((item) => searchParams.has(item)).map(
      (item) => `${item}=${searchParams.get(item)}`,
    );
    if (values.length > 0) {
      return values.join("&");
    }
    return "";
  }, [searchParams]);
};

const hashToNumber = (str: string) => {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

export const avatarFallbackColorPalette = [
  { bgColor: "primitive-purple-700", textColor: "primitive-purple-400" },
  { bgColor: "primitive-purple-500", textColor: "primitive-purple-300" },
  { bgColor: "primitive-purple-300", textColor: "primitive-purple-700" },
  { bgColor: "primitive-purple-200", textColor: "primitive-purple-800" },
  { bgColor: "primitive-purple-800", textColor: "primitive-purple-200" },
  { bgColor: "primitive-purple-700", textColor: "primitive-purple-300" },
  { bgColor: "primitive-purple-400", textColor: "primitive-purple-900" },
];

export const getAvatarFallback = (displayName?: string | null, userId?: string | null) => {
  if (!displayName || !userId) return { monogram: "QU", fallbackColorIndex: 4 };

  const hashedNumber = hashToNumber(userId);

  const fallbackColorIndex = hashedNumber % avatarFallbackColorPalette.length;

  const words = _.words(displayName);
  if (words.length === 0) {
    return { monogram: "QU", fallbackColorIndex: 1 };
  } else if (words.length === 1) {
    return { monogram: words[0].charAt(0), fallbackColorIndex: fallbackColorIndex };
  } else if (words.length >= 2) {
    return { monogram: words[0].charAt(0) + words[1].charAt(0), fallbackColorIndex: fallbackColorIndex };
  }
  return { monogram: "QU", fallbackColorIndex: 1 };
};

export const useUserAiLanguage = (): [SUPPORTED_LANGUAGES, Dispatch<SetStateAction<SUPPORTED_LANGUAGES>>] => {
  const [storageLanguage, setStorageLanguage] = useLocalStorage<SUPPORTED_LANGUAGES>(
    "USER_AI_LANGUAGE",
    SUPPORTED_LANGUAGES.EN,
  );
  const { matched: isDemo } = useIsDemoLikePage();

  useEffect(() => {
    if (!Object.hasOwn(SUPPORTED_LANGUAGES_LABELS, storageLanguage)) {
      setStorageLanguage(SUPPORTED_LANGUAGES.EN);
    }
  }, [setStorageLanguage, storageLanguage]);

  return useMemo(
    () => (isDemo ? [SUPPORTED_LANGUAGES.EN, () => {}] : [storageLanguage, setStorageLanguage]),
    [isDemo, storageLanguage, setStorageLanguage],
  );
};

export const useUserElectedModel = (): [string, Dispatch<SetStateAction<string>>] => {
  const { data, isLoading } = useAvailableModels();
  const [storageModel, setStorageModel] = useLocalStorage<string>("USER_AI_MODEL", "");

  useEffect(() => {
    if (data && data.length > 0 && data.findIndex((item) => item.modelCode === storageModel) === -1) {
      setStorageModel(data[0].modelCode);
    }
  }, [data, setStorageModel, storageModel]);

  return useMemo(
    () => (isLoading ? ["", () => {}] : [storageModel, setStorageModel]),
    [isLoading, storageModel, setStorageModel],
  );
};

export const useTemporaryFDSClaimState = () => {
  return useLocalStorage("temporary-fds-key", "");
};

const TOKEN_KEY = "temporary-fds-id";

export const useHandleFDSClaimCache = () => {
  const [cached, setCached] = useTemporaryFDSClaimState();
  const claimFds = useClaimPreregFDS();
  const addResourceAsChild = useAddDocumentToStore();
  const userId = useFirebaseUserId();
  const navigate = useNavigate();
  const { matched: isDemoLike } = useIsDemoLikePage();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: workspaces } = useWorkspaces();
  const [user] = useAppAuthState();
  useEffect(() => {
    if (isDemoLike) return;
    if (!cached) return;
    if (!userId) return;
    if (!workspaces) return;
    if (!user?.emailVerified) return;
    const toastData = loadingClaimToast();
    setCached("");
    searchParams.delete(TOKEN_KEY);
    setSearchParams(searchParams);

    claimFds.mutate(
      { temporaryDocumentId: cached, unknownDistinctId: mixpanel.get_distinct_id() as string },
      {
        onSuccess: (result) => {
          toast({
            title: "Summary Claimed Successfully",
            variant: "success",
          });
          addResourceAsChild({
            id: cached,
            elementType: ResourceCardType.DOCUMENT_ELEMENT,
            dateCreated: new Date().toISOString(),
            favourite: false,
            name: result.documentName,
            projectId: result.projectId,
            userId: userId ?? "",
          });
          navigate(
            `${ROUTES.DOCUMENTS}/${result.workspaceId}/${result.projectId}?document=${result.documentId}&note-tab=summary`,
          );
        },
        onError: (e) => {
          console.log(e);
          toast({
            title: `Error during claiming document summary! Perhaps you already claimed one!`,
            variant: "destructive",
          });
        },
        onSettled: () => {
          toastData.dismiss();
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, user?.emailVerified, workspaces, cached, isDemoLike]);
};

export function useLetterSize() {
  const [letterSize, setLetterSize] = useTypographyConfig();
  const { mutate: updateUser } = useUpdateFirebaseUserData();
  const mixpanelTrack = useMixpanelTrack();
  const onSizeChange = useCallback(
    (value: FontScale) => {
      setLetterSize((prev) => ({ ...prev, size: value }));
      mixpanelTrack("letter_size_changed", { letter_size: value });
      updateUser({
        payload: {
          letterSize: value,
        },
      });
    },
    [mixpanelTrack, setLetterSize, updateUser],
  );
  return { letterSize, onSizeChange };
}

export const usePublicData = (userId: string) => {
  const [publicDataResponse, loading] = useUserPublicData({ userId });

  return useMemo(() => {
    const data = publicDataResponse?.data();
    return {
      photoUrl: data?.photoUrl,
      avatarFallback: getAvatarFallback(data?.displayName),
      displayName: data?.displayName,
      loading: loading,
      email: data?.email,
    };
  }, [loading, publicDataResponse]);
};

export const useFirebaseAccessToken = () => {
  const [state, setState] = useState("");
  const [user] = useIdToken(auth);

  useEffect(() => {
    void (async () => {
      if (!user) return;
      const val = await user.getIdToken(false);
      setState(val);
    })();
  }, [user]);

  return state;
};
