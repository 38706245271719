import { useEffect, useMemo, useRef, useState } from "react";

import { isSafari } from "react-device-detect";

import { useCurrentDocumentSummaryParagraph, useDocumentSummaryPaginated } from "@/api/document-summary";
import { MODEL_LAKE_URL } from "@/assets/constants/constants.ts";
import { speedValues } from "@/assets/constants/tts";
import { useCurrentDocumentTabEntity } from "@/service/hooks/react-router";
import { useAudioProgression, useCachedAudioUrl } from "@/service/text-2-speech";
import { useFirebaseAccessToken } from "@/service/user.ts";
import { type ParagraphSummary } from "@/types/schemas";
import useAppStateStore from "@/zustand/store";

const ParagraphAudioPlayer = ({ paragraphSummary }: { paragraphSummary: ParagraphSummary }) => {
  const { data: currentDocument } = useCurrentDocumentTabEntity();
  const documentId = currentDocument?.id;
  const { data: summary } = useDocumentSummaryPaginated(documentId);
  const {
    selectedVoice,
    selectedPlaybackSpeed,
    play,
    setTTSLoading,
    setNextDisabled,
    setSelectedChapterIdIndex,
    setSelectedChapterId,
    setPrevDisabled,
    setSelectedParagraphId,
  } = useAppStateStore((state) => ({
    setNextDisabled: state.setNextDisabled,
    setPrevDisabled: state.setPrevDisabled,
    setSelectedParagraphId: state.setSelectedParagraphId,
    setSelectedChapterIdIndex: state.setSelectedChapterIdIndex,
    setSelectedChapterId: state.setSelectedChapterId,
    selectedParagraphId: state.selectedParagraphId,
    selectedVoice: state.selectedVoice,
    selectedPlaybackSpeed: state.selectedPlaybackSpeed,
    play: state.play,
    setTTSLoading: state.setTTSLoading,
  }));

  const [{ finalUrl }, setState] = useState({
    finalUrl: "",
  });

  const { getData, setCache } = useCachedAudioUrl();
  const firebaseToken = useFirebaseAccessToken();
  const audioUrl = useMemo(() => {
    if (!firebaseToken) return "";
    if (isSafari)
      return `${MODEL_LAKE_URL}/text-to-speech/paragraph-summary/?paragraph_summary_id=${paragraphSummary.id}&voice_config=${selectedVoice}&token=${firebaseToken}&stream=false`;
    return `${MODEL_LAKE_URL}/text-to-speech/paragraph-summary/?paragraph_summary_id=${paragraphSummary.id}&voice_config=${selectedVoice}&token=${firebaseToken}&stream=true`;
  }, [paragraphSummary.id, selectedVoice, firebaseToken]);

  useEffect(() => {
    void (async () => {
      const data = await getData(paragraphSummary.id, selectedVoice, paragraphSummary.expanded);
      if (data) {
        setState({
          finalUrl: URL.createObjectURL(data.fileData),
        });
      } else {
        setTTSLoading(true);
        setState({
          finalUrl: audioUrl,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paragraphSummary, selectedVoice, audioUrl]);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = speedValues[selectedPlaybackSpeed];
    }
  }, [selectedPlaybackSpeed]);

  useEffect(() => {
    if (audioRef.current && play && finalUrl) {
      audioRef.current.playbackRate = speedValues[selectedPlaybackSpeed];
      setTimeout(() => {
        void audioRef?.current?.play();
      }, 500);
    } else if (audioRef.current && !play) {
      audioRef.current.pause();
    } else if (audioRef.current && !finalUrl) {
      audioRef.current.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [play, finalUrl]);

  useAudioProgression({
    audioRef,
    summary,
  });

  useEffect(() => {
    const handleAudioLoaded = async (e: Event) => {
      const target = e.target as HTMLMediaElement;

      const summaryId = new URLSearchParams(new URL(target.src).searchParams).get("paragraph_summary_id") ?? "";
      const voice = new URLSearchParams(new URL(target.src).searchParams).get("voice_config") ?? "";
      if (!summaryId) {
        setTTSLoading(false);
        return;
      }
      // Fetch the audio data
      const response = await fetch(target.src);
      const arrayBuffer = await response.arrayBuffer();

      // Convert to Blob
      const blob = new Blob([arrayBuffer], { type: "audio/mpeg" });
      setTTSLoading(false);

      setCache(paragraphSummary.id, voice, paragraphSummary.expanded, blob);
    };

    audioRef.current?.addEventListener("loadeddata", handleAudioLoaded);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      audioRef.current?.removeEventListener("loadeddata", handleAudioLoaded);
    };
  }, [audioRef, paragraphSummary, setCache, setTTSLoading]);

  useEffect(() => {
    if (summary && summary.chapterIdOrderedList.length > 0) {
      const initialChapterId = summary.chapterIdOrderedList[0];

      setSelectedChapterId(initialChapterId);
      setSelectedChapterIdIndex(0);

      const initialParagraphId = summary?.paragraphSummariesOfChapter[initialChapterId]?.[0];

      setSelectedParagraphId(initialParagraphId);
      setPrevDisabled(false);
      setNextDisabled(false);
    }
  }, [
    summary,
    setSelectedChapterId,
    setSelectedChapterIdIndex,
    setSelectedParagraphId,
    setPrevDisabled,
    setNextDisabled,
  ]);

  return (
    <>
      <audio controls className="hidden" ref={audioRef} src={finalUrl || undefined}>
        Your browser does not support the <code>audio</code> element.
      </audio>
    </>
  );
};

export const PlayerWrapper = () => {
  const { selectedParagraphId } = useAppStateStore((state) => ({
    selectedParagraphId: state.selectedParagraphId,
  }));

  const paragraphSummary = useCurrentDocumentSummaryParagraph(selectedParagraphId);
  if (!paragraphSummary) return null;

  return <ParagraphAudioPlayer paragraphSummary={paragraphSummary} />;
};

export default PlayerWrapper;
