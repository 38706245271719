import { z } from "zod";

export enum VoiceLanguageCodes {
  EN = "en",
}

const availableVoicesSchema = z.enum(["DEFAULT", "STEPHANIE", "ANTHONY", "HARRY", "JASON", "HARPER", "CAROL"]);

export type AvailableVoicesSchema = z.infer<typeof availableVoicesSchema>;

export const availableVoicesArray = z.array(availableVoicesSchema);
