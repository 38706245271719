import { type QueryClient } from "@tanstack/react-query";

import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { GenericFetchError } from "@/service/errors";
import { type NoteEntity, type NoteParagraph } from "@/types/schemas";
import { getDefaultHeaders, getStrapiDefaultHeaders } from "@/utils";

const genericFetch = async <T>(
  url: string,
  method = "GET",
  body?: object,
  signal?: AbortSignal,
  customHeaders?: HeadersInit,
) => {
  let data;

  if (body instanceof FormData) {
    data = body;
  } else if (typeof body === "object") {
    data = JSON.stringify(body);
  }

  const res = await fetch(url, {
    method,
    headers: { ...(await getDefaultHeaders(!(body instanceof FormData))), ...customHeaders },
    body: data,
    signal,
  });
  if (!res.ok) throw new GenericFetchError("Request failed", res);
  if (res.status === 202) return { result: "ok" } as unknown as T;
  if (res.headers.get("content-length") === "0") return {} as unknown as T;
  if (res.headers.get("content-type")?.includes("application/json")) return (await res.json()) as unknown as T;
  if (res.body) return res.body as unknown as T;
  return (await res.text()) as unknown as T;
};

const genericStrapiFetch = async <T>(url: string, method = "GET", body?: object) => {
  const res = await fetch(url, {
    method,
    headers: getStrapiDefaultHeaders(),
    body: JSON.stringify(body),
  });
  if (!res.ok) throw new Error("Request failed");
  return (await res.json()) as unknown as T;
};

export const postRequest = async <T>(url: string, body: object = {}, signal?: AbortSignal) =>
  genericFetch<T>(url, "POST", body, signal);

export const postStrapiRequest = async <T>(url: string, body: object) => genericStrapiFetch<T>(url, "POST", body);

export const getRequest = async <T>(url: string, signal?: AbortSignal, customHeaders?: HeadersInit) =>
  genericFetch<T>(url, "GET", undefined, signal, customHeaders);

export const getStrapiRequest = async <T>(url: string) => genericStrapiFetch<T>(url, "GET");

export const deleteRequest = async <T>(url: string, body?: object) => genericFetch<T>(url, "DELETE", body);

export const deleteStrapiRequest = async <T>(url: string) => genericStrapiFetch<T>(url, "DELETE");

export const putRequest = async <T>(url: string, body?: object) => genericFetch<T>(url, "PUT", body);

export const putStrapiRequest = async <T>(url: string, body: object) => genericStrapiFetch<T>(url, "PUT", body);

export const insertParagraphIntoNoteEntity = ({
  workspaceId,
  result,
  queryClient,
}: {
  workspaceId: string;
  result: NoteParagraph;
  queryClient: QueryClient;
}) => {
  queryClient.setQueryData<NoteEntity>([QUERY_KEYS.NOTE, workspaceId, { id: result.noteId }], (oldData) => {
    if (!oldData) return oldData;
    const newData = oldData.noteParagraphs.sort((a, b) => a.position - b.position);
    for (let i = result.position + 1; i < newData.length; i++) {
      newData[i].position += 1;
    }
    newData.splice(result.position, 0, result);
    return {
      ...oldData,
      noteParagraphs: newData,
    };
  });
};
