import { useMemo } from "react";

import {
  type InfiniteData,
  type QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { fetchDemoDocumentSummary, fetchDemoDocumentSummaryPaged, updateDemoParagraphSummary } from "@/api/demo";
import { useSubscription } from "@/api/resources";
import { getRequest, postRequest, putRequest } from "@/api/utils.ts";
import { useWorkspace } from "@/api/workspace";
import { BACKEND_URL, ROUTES, summarizationStatus } from "@/assets/constants/constants.ts";
import { ModalKeys } from "@/assets/constants/modal";
import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { toast } from "@/components/ui/use-toast.ts";
import { useFirebaseUserId } from "@/firebase/hooks.ts";
import { combinePages } from "@/service/document-summary.ts";
import { type GenericFetchError } from "@/service/errors";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useCurrentDocumentId, useCurrentWorkspaceId } from "@/service/hooks/react-router";
import { useUpgradePlanModal } from "@/service/library.ts";
import { useUserAiLanguage } from "@/service/user.ts";
import type { SUPPORTED_LANGUAGES } from "@/types/interfaces/library.tsx";
import type { QueryConfig } from "@/types/react-query";
import {
  type BasicResponse,
  type DocumentSummaryRedisTicker,
  documentSummaryRedisTickerSchema,
  type ParagraphSummary,
  type ParagraphSummaryUpdate,
  paragraphUpdateResponse,
  type ParagraphUpdateResponse,
  type SubscriptionResponse,
  type SummaryResponse,
  summaryResponseSchema,
} from "@/types/schemas";
import { validateZodSchema } from "@/utils";
import { useModalState } from "@/zustand/slices/modal-slice";

const DOCUMENT_SUMMARY = "document-summary";

export const generateDocumentSummary = async (payload: {
  expectedResponseLanguage: SUPPORTED_LANGUAGES;
  documentId: string;
}): Promise<BasicResponse> => postRequest(`${BACKEND_URL}/${DOCUMENT_SUMMARY}/make-summary`, payload);

export const getDocumentSummary = async (documentId: string, language: string): Promise<SummaryResponse> =>
  await validateZodSchema(
    getRequest(`${BACKEND_URL}/${DOCUMENT_SUMMARY}/summary-of-document/${documentId}/${language}`),
    summaryResponseSchema,
  );

export const getDocumentSummaryPaged = async (
  documentId: string,
  language: string,
  offset: number = 0,
  limit: number = 25,
): Promise<SummaryResponse> =>
  await validateZodSchema(
    getRequest(
      `${BACKEND_URL}/${DOCUMENT_SUMMARY}/summary-of-document/${documentId}/${language}?limit=${limit}&offset=${offset}`,
    ),
    summaryResponseSchema,
  );

export const getTemporaryDocumentSummary = (documentId: string = "DOCUMENT_SUMMARY_ONE") =>
  validateZodSchema(
    getRequest<SummaryResponse>(`${BACKEND_URL}/documents/prereg-fds/${documentId}`),
    summaryResponseSchema,
  );

export const updateParagraphSummary = async ({
  paragraphSummaryId,
  projectId,
  documentId,
  workspaceId,
  payload,
}: {
  workspaceId: string;
  projectId: string;
  documentId: string;
  paragraphSummaryId: string;
  payload: Partial<ParagraphSummaryUpdate>;
}) => {
  return validateZodSchema(
    putRequest<ParagraphUpdateResponse>(
      `${BACKEND_URL}/${DOCUMENT_SUMMARY}/paragraph-summary/${workspaceId}/${projectId}/${documentId}/${paragraphSummaryId}`,
      payload,
    ),
    paragraphUpdateResponse,
  );
};

export const extendParagraphSummary = async ({ paragraphSummaryId }: { paragraphSummaryId: string }) => {
  return validateZodSchema(
    putRequest<ParagraphUpdateResponse>(
      `${BACKEND_URL}/${DOCUMENT_SUMMARY}/paragraph-summary/${paragraphSummaryId}/extend`,
    ),
    paragraphUpdateResponse,
  );
};

export const fetchDocumentSummaryStatus = (documentId: string, language: string) =>
  validateZodSchema(
    getRequest<DocumentSummaryRedisTicker>(
      `${BACKEND_URL}/${DOCUMENT_SUMMARY}/progress-of-document-summary/${documentId}/${language}`,
    ),
    documentSummaryRedisTickerSchema,
  );

export const useDocumentSummaryProcessingStatus = (
  documentId?: string,
  options?: QueryConfig<DocumentSummaryRedisTicker>,
) => {
  const [language] = useUserAiLanguage();
  return useQuery({
    queryKey: ["document-summary-processing-status", documentId, language],
    queryFn: () => fetchDocumentSummaryStatus(documentId!, language),
    enabled: !!documentId && (options?.enabled !== undefined ? options.enabled : true),
    refetchInterval: 5000,
  });
};

export const useDocumentSummary = (documentId?: string, options?: QueryConfig<SummaryResponse>) => {
  const { matched: isDemoPage } = useIsDemoLikePage();
  const [language] = useUserAiLanguage();
  const { data, ...rest } = useQuery({
    queryKey: ["summary", documentId, language],
    queryFn: () => (isDemoPage ? fetchDemoDocumentSummary(documentId) : getDocumentSummary(documentId ?? "", language)),
    enabled: documentId !== undefined && documentId !== "" && (options ? options.enabled : true),
    retry: 0,
  });

  return { data: data, ...rest };
};

export const useDocumentSummaryPaginated = (documentId?: string, options?: QueryConfig<SummaryResponse>) => {
  const { route } = useIsDemoLikePage();
  const [language] = useUserAiLanguage();
  const { data, ...rest } = useInfiniteQuery({
    queryKey: ["summary", documentId, language, "paginated"],
    queryFn: async ({ pageParam }) => {
      if (route === ROUTES.TEMPORARY) return getTemporaryDocumentSummary(documentId);
      if (route === ROUTES.DEMO) return fetchDemoDocumentSummaryPaged(documentId, pageParam);
      try {
        return await getDocumentSummaryPaged(documentId ?? "", language, pageParam);
      } catch (error) {
        const _error = error as GenericFetchError;
        console.log(_error.payload);
        throw _error.payload;
      }
    },
    initialPageParam: 0,
    enabled: documentId !== undefined && documentId !== "" && (options ? options.enabled : true),
    getNextPageParam: (lastPage, allPages) => (lastPage.hasMore ? allPages.length : undefined),
    retry: 0,
  });

  // console.log(data?.pages)

  const mappedData = useMemo(
    () => combinePages(data?.pages),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(data),
    ],
  );

  // console.log(mappedData);
  return { data: mappedData, ...rest };
};

export const useDocumentSummaryParagraph = (documentId: string, paragraphId: string) => {
  const { data } = useDocumentSummaryPaginated(documentId);
  const [language] = useUserAiLanguage();
  return useMemo(
    () =>
      data && paragraphId && Object.hasOwn(data.paragraphEntities, paragraphId)
        ? data.paragraphEntities[paragraphId]
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(data?.paragraphEntities), documentId, paragraphId, language],
  );
};
export const useCurrentDocumentSummaryParagraph = (paragraphId: string) => {
  const [documentId] = useCurrentDocumentId();
  const { data } = useDocumentSummaryPaginated(documentId);
  const [language] = useUserAiLanguage();
  return useMemo(
    () =>
      data && paragraphId && Object.hasOwn(data.paragraphEntities, paragraphId)
        ? data.paragraphEntities[paragraphId]
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(data?.paragraphEntities), documentId, paragraphId, language],
  );
};

const updateParagraphSummaryInPages = (
  pages: SummaryResponse[],
  paragraphId: string,
  paragraphSummary: Partial<ParagraphSummary>,
) => {
  pages.some((page) => {
    if (Object.hasOwn(page.paragraphEntities, paragraphId)) {
      page.paragraphEntities[paragraphId] = {
        ...page.paragraphEntities[paragraphId],
        ...paragraphSummary,
      };
      return true;
    }
    return false;
  });
  return pages;
};

export const useExtendParagraphSummary = () => {
  const queryClient = useQueryClient();
  const [expectedResponseLanguage] = useUserAiLanguage();

  return useMutation({
    mutationFn: ({ paragraphId }: { paragraphId: string; documentId: string }) =>
      extendParagraphSummary({ paragraphSummaryId: paragraphId }),
    onSuccess: (result, { documentId, paragraphId }) => {
      queryClient.setQueryData<InfiniteData<SummaryResponse>>(
        ["summary", documentId, expectedResponseLanguage, "paginated"],
        (oldData) =>
          oldData
            ? {
                ...oldData,
                pages: updateParagraphSummaryInPages(oldData.pages, paragraphId, { ...result, expanded: true }),
              }
            : oldData,
      );
    },
  });
};

export const useUpdateParagraphSummary = () => {
  const queryClient = useQueryClient();
  const { matched: isDemo } = useIsDemoLikePage();
  const [expectedResponseLanguage] = useUserAiLanguage();
  return useMutation({
    mutationFn: ({
      paragraphId,
      documentId,
      payload,
      ...rest
    }: {
      documentId: string;
      projectId: string;
      workspaceId: string;
      paragraphId: string;
      paragraphSummaryId: string;
      payload: Partial<ParagraphSummaryUpdate>;
    }) => {
      queryClient.setQueryData<InfiniteData<SummaryResponse>>(
        ["summary", documentId, expectedResponseLanguage, "paginated"],
        (oldData) =>
          oldData
            ? {
                ...oldData,
                pages: updateParagraphSummaryInPages(oldData.pages, paragraphId, {
                  highlighted: payload.highlighted,
                }),
              }
            : oldData,
      );

      return isDemo
        ? updateDemoParagraphSummary({ ...rest, payload })
        : updateParagraphSummary({ ...rest, documentId, payload });
    },
    onSuccess: (result, { documentId, paragraphId }) => {
      queryClient.setQueryData<InfiniteData<SummaryResponse>>(
        ["summary", documentId, expectedResponseLanguage, "paginated"],
        (oldData) =>
          oldData
            ? {
                ...oldData,
                pages: updateParagraphSummaryInPages(oldData.pages, paragraphId, result),
              }
            : oldData,
      );
    },
  });
};

export const useMakeSummarization = (documentId: string, queryClient: QueryClient) => {
  const [, setUpdatePlanModalState] = useUpgradePlanModal();
  const userId = useFirebaseUserId();
  const [expectedResponseLanguage] = useUserAiLanguage();

  const currentWorkspaceId = useCurrentWorkspaceId();
  const { data: workspace } = useWorkspace(currentWorkspaceId);
  const isSingleUser = workspace ? workspace?.userIds.length < 2 : true;
  const [, setOpen] = useModalState(ModalKeys.GET_PRO_CTA_MODAL);
  const { data: userCurrentPlan } = useSubscription();

  const { mutate, isPending } = useMutation({
    mutationFn: () => generateDocumentSummary({ expectedResponseLanguage, documentId }),
    onSuccess: () => {
      if (isSingleUser && userCurrentPlan?.subscriptionPlan === "free") {
        setOpen(true);
      }

      queryClient.setQueryData<InfiniteData<SummaryResponse>>(
        ["summary", documentId, expectedResponseLanguage, "paginated"],
        (oldData) => {
          console.log(oldData);
          if (!oldData) return oldData;
          if (oldData.pages[0]) {
            oldData.pages[0].summarizationStatus = summarizationStatus.enum.UNDER_SUMMARIZATION;
          }
          return oldData;
        },
      );

      queryClient.setQueryData<SubscriptionResponse>([QUERY_KEYS.MY_SUBSCRIPTION, userId], (data) => {
        if (!data) return data;

        return {
          ...data,
          usageMetrics: data.usageMetrics
            ? {
                ...data.usageMetrics,
                monthlySingleWorkspaceFdsUsage: data.usageMetrics.monthlySingleWorkspaceFdsUsage
                  ? data.usageMetrics.monthlySingleWorkspaceFdsUsage + 1
                  : data.usageMetrics.monthlySingleWorkspaceFdsUsage,
              }
            : data.usageMetrics,
        };
      });
    },
    onError: (e: GenericFetchError) => {
      if (e?.payload?.status === 403) {
        setUpdatePlanModalState({ open: true });
      } else
        toast({
          title: "An error occurred while generating the summary. Please try again",
          variant: "destructive",
        });
    },
  });
  return { mutate, isPending };
};
