import { useDocumentSummaryPaginated, useDocumentSummaryProcessingStatus } from "@/api/document-summary.ts";
import { summarizationStatus } from "@/assets/constants/constants.ts";
import { getSummaryPercentage } from "@/components/document/summary/utils.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { InfiniteLoading } from "@/pages/loading";
import { useCurrentDocumentTabId } from "@/service/hooks/react-router.ts";
import { useIsFreeUser } from "@/service/hooks/settings.ts";

const DocumentSummaryIndicator = () => {
  const currentDocId = useCurrentDocumentTabId();
  const { data: summary } = useDocumentSummaryPaginated(currentDocId);
  const { data: documentSummaryProgress } = useDocumentSummaryProcessingStatus(currentDocId, {
    enabled: summary?.summarizationStatus === summarizationStatus.enum.UNDER_SUMMARIZATION,
  });
  const isFreeUser = useIsFreeUser();
  const underSummarization = summary?.summarizationStatus === summarizationStatus.enum.UNDER_SUMMARIZATION;

  if (!isFreeUser) return null;

  if (!underSummarization) return null;

  const progressValue = Math.round(getSummaryPercentage(documentSummaryProgress));

  return (
    <div className="fixed bottom-8 left-8 z-50 flex min-w-[16rem] flex-col gap-2 rounded-lg border border-stroke-default bg-white text-primary shadow-elevation-2">
      <div className="mx-auto flex max-w-sm flex-col justify-center gap-4 overflow-hidden rounded-2xl bg-white p-4">
        <Typography className="text-base font-bold">Summarizing document...</Typography>
        <div className="flex min-w-[16rem] flex-row items-center gap-4 bg-white text-primary">
          <section className="relative flex flex-row gap-2">
            <section className="relative">
              <InfiniteLoading className="h-20 w-20" value={progressValue} />
            </section>
          </section>
          <section className="font-jakarta text-sm">Your summary is being generated, please wait a moment...</section>
        </div>
      </div>
    </div>
  );
};

export default DocumentSummaryIndicator;
