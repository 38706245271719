import { useEffect } from "react";

import { useLocalStorage } from "usehooks-ts";

import { useDocument, useRegenerateCover, useReVectorizedDocument } from "@/api/document.ts";
import { DEMO_USER_ID } from "@/assets/constants/demo.ts";
import { TutorialVariant } from "@/assets/constants/modal";
import ChapterSelectorPopover from "@/components/document/chapter-selector-popover.tsx";
import { MaskedNameForFunnyStuff } from "@/components/funny-stuff/google.tsx";
import { EmptyDocumentView } from "@/components/pdf/components";
import { PdfViewer } from "@/components/pdf/index";
import { useDocumentProcessing, useGetCachedDocumentUrl } from "@/service/hooks/documents.ts";
import { useIsFreeUser } from "@/service/hooks/settings.ts";
import { useTutorial } from "@/service/hooks/tutorial";
import { type PdfViewerWrapperProps } from "@/types/app.ts";
import { cn, useCurrentProjectId } from "@/utils";

const PdfViewerWrapper = ({ workspaceId, documentId, hidden }: PdfViewerWrapperProps) => {
  const [runOnce] = useLocalStorage("document-tutorial-run", false);

  const { setState: setTutorialState } = useTutorial(TutorialVariant.DOCUMENT_VIEW);
  const { data: documentEntity, isLoading: isLoadingDocument } = useDocument(workspaceId, documentId);
  const selectedProject = useCurrentProjectId();

  const { mutate: reVectorize } = useReVectorizedDocument();
  const { mutate: regenerateCover } = useRegenerateCover();
  const { processing } = useDocumentProcessing();
  const isFreeUser = useIsFreeUser();
  const { data: fileDataUrl, isLoading } = useGetCachedDocumentUrl({
    documentId: documentId,
    userId: documentEntity?.userId ?? DEMO_USER_ID,
    selectedProject: selectedProject ?? "",
    inheritedUrl: documentEntity?.inheritedFromUrl,
  });

  useEffect(() => {
    if (fileDataUrl && !runOnce) {
      setTimeout(() => {
        setTutorialState({ run: true });
      }, 1000);
    }
  }, [runOnce, fileDataUrl, setTutorialState]);

  useEffect(() => {
    if (fileDataUrl && documentEntity && documentEntity.documentDetails.processingStatus === "NOT_PROCESSED")
      reVectorize(documentId);
  }, [fileDataUrl, documentEntity, documentId, reVectorize]);

  useEffect(() => {
    if (
      documentEntity &&
      documentEntity.documentDetails &&
      documentEntity.coverImageUrl === null &&
      documentEntity.documentDetails.processingStatus === "PROCESSED" &&
      ["OK", "TOLERABLE_PDF_ERRORS"].includes(documentEntity.documentDetails.documentHealthLevel ?? "")
    ) {
      regenerateCover(documentEntity.id);
    }
  }, [documentEntity, regenerateCover]);

  if (isLoading || isLoadingDocument) return <EmptyDocumentView hidden={hidden} />;

  if (processing && isFreeUser)
    return (
      <section className="flex h-full w-full items-center justify-center">
        <section className="h-[250px] w-[300px]">
          <MaskedNameForFunnyStuff
            format="inline"
            responsive={undefined}
            slot="1256740992"
            style={{
              display: "inline-block",
              width: "300px",
              height: "250px",
            }}
          />
        </section>
      </section>
    );

  if (fileDataUrl && documentEntity) {
    return (
      <section
        className={cn(
          "relative block h-full overflow-hidden bg-white data-[hidden=true]:hidden",
          `tutorial-pdf-viewer ${hidden ? "" : "active"}`,
        )}
        data-hidden={hidden}
      >
        <PdfViewer
          documentId={documentId}
          documentName={documentEntity.name}
          fileUrl={fileDataUrl}
          workspaceId={workspaceId}
        />
        <ChapterSelectorPopover documentId={documentId} />
      </section>
    );
  }

  return (
    <section className="block h-full overflow-hidden bg-white data-[hidden=true]:hidden">
      <p className="mx-auto mt-[10%] w-10/12 font-bold text-primary">Invalid storage file url!</p>
    </section>
  );
};
export default PdfViewerWrapper;
