import { useMemo } from "react";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar.tsx";
import { useAuthUserData, useMyAvatarFallback } from "@/firebase/hooks.ts";
import { LoadingSpinner } from "@/pages/loading";

const MyUserAvatar = ({ large = false }: { large?: boolean }) => {
  const { data: userData, isLoading } = useAuthUserData();

  const avatarFallback = useMyAvatarFallback();

  return useMemo(() => {
    if (isLoading) return <LoadingSpinner />;

    console.log(userData);

    return large ? (
      <Avatar className="h-36 w-36">
        <AvatarImage alt="user-avatar" className="h-36 w-36 bg-default" src={userData?.photoUrl ?? ""} />
        <AvatarFallback className="text-7xl font-extrabold" fallbackColorIndex={avatarFallback.fallbackColorIndex}>
          {avatarFallback.monogram}
        </AvatarFallback>
      </Avatar>
    ) : (
      <Avatar className="h-12 w-12">
        <AvatarImage alt="user-avatar" className="h-12 w-12 bg-default" src={userData?.photoUrl ?? ""} />
        <AvatarFallback className="text-xl" fallbackColorIndex={avatarFallback.fallbackColorIndex}>
          {avatarFallback.monogram}
        </AvatarFallback>
      </Avatar>
    );
  }, [avatarFallback, isLoading, large, userData?.photoUrl]);
};

export default MyUserAvatar;
