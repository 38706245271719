import * as React from "react";
import { useMemo } from "react";

import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { useTypographyConfig } from "@/service/hooks/misc";
import { type FontScale } from "@/types/app.ts";
import { cn } from "@/utils";

const typographyVariants = cva("whitespace-pre-wrap font-jakarta", {
  variants: {
    variant: {
      default: "text-primary dark:text-white",
      secondary: "text-secondary-onBg",
    },
    size: {
      "ultra-small": "text-[0.625rem]",
      "extra-small": "text-xs",
      sm: "text-sm",
      small: "text-base leading-6",
      normal: "text-lg leading-[1.75rem]",
      large: "text-xl leading-[135%]",
      xl: "text-[1.25rem] leading-[1.75rem]",
      "2xl": "text-2xl",
      "3xl": "text-3xl",
      h1: "text-h1",
      h2: "text-h2",
      h3: "text-h3",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "normal",
  },
});

export interface TypographyProps
  extends React.ParamHTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof typographyVariants> {
  asChild?: boolean;
  large?: boolean;
}

const getFontVariant = (variant: FontScale, scale?: boolean) => {
  if (scale) {
    switch (variant) {
      case "small":
        return "xl";
      case "normal":
        return "2xl";
      case "large":
        return "3xl";
    }
  }
  return variant;
};

const Typography = React.forwardRef<HTMLParagraphElement, TypographyProps>(
  ({ className, variant, large, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "p";
    const [config] = useTypographyConfig();
    const selectedVariant = useMemo(() => (variant ? variant : config.variant), [config, variant]);

    const selectedSize = useMemo(() => (size ? size : getFontVariant(config.size, large)), [config.size, large, size]);

    return (
      <Comp
        className={cn(
          typographyVariants({
            variant: selectedVariant,
            size: selectedSize,
          }),
          className,
        )}
        data-size={selectedSize}
        ref={ref}
        {...props}
      />
    );
  },
);

Typography.displayName = "Typography";

export { Typography };
