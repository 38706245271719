import { useEffect, useMemo } from "react";

import { Outlet, useNavigate } from "react-router-dom";

import { useCreateWorkspace, useWorkspaces } from "@/api/workspace.ts";
import { MaskedNameForFunnyStuff } from "@/components/funny-stuff/google.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { NewWorkspaceButton, WorkspaceCard, WorkspaceExplorerHeader, WorkspaceHeaderBreadcrumb } from "@/components/v3";
import { InfiniteLoading } from "@/pages/loading";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useClearSelectionOnPageChange } from "@/service/library.ts";

const Workspaces = () => {
  useClearSelectionOnPageChange();
  const navigate = useNavigate();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const { mutate: createWorkspace } = useCreateWorkspace();
  const {
    data: workspaces,
    isLoading,
    isError,
    isSuccess,
  } = useWorkspaces({
    refetchOnMount: true,
    staleTime: 5000,
  });
  const anyShared = useMemo(() => {
    return workspaces && workspaces.some((item) => item.numOfUsers > 1);
  }, [workspaces]);
  useEffect(() => {
    if (!isSuccess) return;
    if (workspaces.length > 0 && !currentWorkspaceId) {
      navigate(workspaces[0].id);
    } else if (workspaces.length === 0) {
      createWorkspace({
        name: "My workspace",
      });
    }
  }, [isSuccess, workspaces, currentWorkspaceId, navigate, createWorkspace]);

  if (isError) return <div>Error...</div>;
  if (isLoading)
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center">
        <InfiniteLoading />
      </div>
    );
  return (
    <section className="flex w-full flex-1 flex-row gap-10">
      <section className="flex flex-col gap-6 lg:w-[16.5rem]">
        <section className="flex flex-row items-center justify-center lg:justify-between">
          <Typography className="hidden font-bold lg:block" size="large">
            Workspaces
          </Typography>
          <NewWorkspaceButton />
        </section>
        <section className="flex flex-col gap-1 overflow-y-auto">
          {workspaces?.map((workspace) => <WorkspaceCard key={workspace.id} workspace={workspace} />)}
          <p
            className="hidden:hidden hidden w-full rounded-lg border border-dashed border-stroke-default p-2 text-center text-xs lg:block"
            hidden={anyShared}
          >
            Study together with your friends in through shared workspaces!
          </p>
        </section>
        <section className="">
          <MaskedNameForFunnyStuff
            format="inline"
            responsive="true"
            slot="1432038805"
            style={{ display: "inline-block", height: "214px", width: "100%" }}
          />
        </section>
      </section>
      <section className="w-full flex-1">
        <section className="flex h-full w-full flex-col gap-6">
          <WorkspaceHeaderBreadcrumb />
          <WorkspaceExplorerHeader />
          <section className="flex-1">
            <Outlet />
          </section>
        </section>
      </section>
    </section>
  );
};

export default Workspaces;
