import { type ClassAttributes, type HTMLAttributes } from "react";

import ReactMarkdown, { type Components, type ExtraProps } from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { visit } from "unist-util-visit";

import PlanButton from "@/components/button/plan-button";
import { Divider } from "@/components/ui/divider";
import { Typography } from "@/components/ui/typography";
import { useTypographyConfig } from "@/service/hooks/misc.ts";
import { type FontScale } from "@/types/app.ts";
import { cn } from "@/utils/index.ts";

export const JSONStringifier = ({ data }: { data: unknown }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

function rehypePluginAddingIndex() {
  /**
   * @param {import('hast').Root} tree
   * @returns {undefined}
   */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return function (tree: any) {
    let headingCount = 0;
    visit(tree, function (node, index) {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        node.type === "element" &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
        ["h1", "h2", "h3", "h4", "h5", "h6"].includes(node.tagName) &&
        typeof index === "number"
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        node.properties.index = headingCount;
        headingCount++;
      }
    });
  };
}

export const QuinoMarkdownChat = ({ children = "" }: { children?: string }) => (
  <ReactMarkdown
    className="preserved-whitespace mb-[1px]"
    components={{
      h1: ({ node: _node, ...props }) => (
        <Typography asChild className="my-2 text-6xl font-bold [&>strong]:font-extrabold">
          <h1 {...props} />
        </Typography>
      ),
      h2: ({ node: _node, ...props }) => (
        <Typography asChild className="my-2 text-4xl font-bold leading-10 [&>strong]:font-bold">
          <h2 {...props} />
        </Typography>
      ),
      h3: ({ node: _node, ...props }) => (
        <Typography asChild className="my-2 text-3xl font-bold [&>strong]:font-bold">
          <h3 {...props} />
        </Typography>
      ),
      h4: ({ node: _node, ...props }) => (
        <Typography asChild className="my-2 text-2xl font-bold [&>strong]:font-bold">
          <h4 {...props} />
        </Typography>
      ),
      h5: ({ node: _node, ...props }) => (
        <Typography asChild className="my-2 text-xl font-bold [&>strong]:font-semibold">
          <h5 {...props} />
        </Typography>
      ),
      h6: ({ node: _node, ...props }) => (
        <Typography asChild className="my-2 text-lg font-bold [&>strong]:font-semibold">
          <h6 {...props} />
        </Typography>
      ),
      em: ({ node, ...props }) => {
        if (children[0] && typeof children[0] === "string" && children[0].startsWith("^")) {
          return <sup>{children[0].substring(1)}</sup>;
        }
        if (children[0] && typeof children[0] === "string" && children[0].startsWith("~")) {
          return <sub>{children[0].substring(1)}</sub>;
        }
        return <i {...props} />;
      },
      ul: ({ node: _node, ...props }) => (
        <Typography asChild className="whitespace-normal">
          <ul className="list-outside list-disc pl-5 [&>li>ul]:list-[square] [&>li]:marker:h-0" {...props} />
        </Typography>
      ),
      ol: ({ node: _node, ...props }) => (
        <Typography asChild className="whitespace-normal">
          <ol className="list-outside list-decimal whitespace-normal pl-5 [&>li]:marker:text-base" {...props} />
        </Typography>
      ),
      li: ({ node: _node, ...props }) => (
        <Typography asChild className="whitespace-normal leading-8 marker:text-[8px]">
          <li {...props} />
        </Typography>
      ),
      a: ({ node: _node, ...props }) => (
        <Typography asChild className="leading-6 text-primary-blue">
          <a {...props} />
        </Typography>
      ),
      p: ({ node: _node, ...props }) => (
        <Typography asChild className="my-2 text-base leading-6 first:mt-0 last:mb-0">
          <p {...props} />
        </Typography>
      ),
      table: ({ node: _node, ...props }) => <table className="w-full table-fixed" {...props} />,
      tr: ({ node: _node, ...props }) => <tr className="min-h-[34px] border" {...props} />,
      td: ({ node: _node, children, ...props }) => (
        <td className="border px-2 py-1" {...props}>
          <div className="min-h-[34px] break-words">{children}</div>
        </td>
      ),
      th: ({ node: _node, children, ...props }) => (
        <th className="min-h-[34px] border px-2 py-1" {...props}>
          <div className="min-h-[34px] break-words">{children}</div>
        </th>
      ),
      u: ({ node: _node, ...props }) => <u {...props} />,
      strong: ({ node: _node, ...props }) => <strong {...props} />,
    }}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
    rehypePlugins={[rehypeRaw]}
    remarkPlugins={[remarkGfm]}
  >
    {children}
  </ReactMarkdown>
);

type ExtendedHeading = ClassAttributes<HTMLHeadingElement> &
  HTMLAttributes<HTMLHeadingElement> &
  ExtraProps & { index?: number };

export const QuinoMarkdown = ({ children = "" }: { children?: string }) => (
  <ReactMarkdown
    className="overflow-x-auto overflow-y-hidden text-primary"
    components={{
      a: (props) => (
        <a className="visited:text-primary-purple-800 font-bold text-primary-purple underline" {...props} />
      ),
      h1(props: ExtendedHeading) {
        return (
          <h1
            className="scroll-mt-8 pb-4 text-landing-h1 font-semibold text-primitive-purple-950 md:scroll-mt-12 md:pb-6"
            id={`heading-${props.index}`}
          >
            {props.children}
          </h1>
        );
      },
      h2(props: ExtendedHeading) {
        return (
          <h2
            className="scroll-mt-8 pb-4 text-h2 font-semibold text-primitive-purple-950 md:scroll-mt-12 md:text-[2rem]/[2.52rem]"
            id={`heading-${props.index}`}
          >
            {props.children}
          </h2>
        );
      },
      h3(props: ExtendedHeading) {
        return (
          <h3
            className="scroll-mt-8 pb-4 text-h3 font-semibold text-primitive-purple-950 md:scroll-mt-12"
            id={`heading-${props.index}`}
          >
            {props.children}
          </h3>
        );
      },
      h4(props: ExtendedHeading) {
        return (
          <h4
            className="scroll-mt-8 pb-4 text-landing-h4 font-semibold text-primitive-purple-950 md:scroll-mt-12"
            id={`heading-${props.index}`}
          >
            {props.children}
          </h4>
        );
      },
      h5(props: ExtendedHeading) {
        return (
          <h4
            className="scroll-mt-8 pb-4 text-lg font-semibold text-primitive-purple-950 md:scroll-mt-12"
            id={`heading-${props.index}`}
          >
            {props.children}
          </h4>
        );
      },
      h6(props: ExtendedHeading) {
        return (
          <h4
            className="scroll-mt-8 pb-4 text-lg font-semibold text-primitive-purple-950 md:scroll-mt-12"
            id={`heading-${props.index}`}
          >
            {props.children}
          </h4>
        );
      },
      p(props) {
        return <p className="mb-4 text-landing-base-adp text-primitive-grey-800 md:mb-10">{props.children}</p>;
      },
      ul(props) {
        return (
          <ul className="ml-6 list-disc marker:font-bold marker:text-primary-container-default">{props.children}</ul>
        );
      },
      ol(props) {
        return (
          <ol className="ml-6 list-decimal pl-0.5 marker:font-bold marker:text-primary-container-default">
            {props.children}
          </ol>
        );
      },
      li(props) {
        return <li className="pb-3 text-primitive-grey-800">{props.children}</li>;
      },
      em(props) {
        return (
          <span className="relative mt-6 block rounded-lg bg-primitive-purple-100 p-3">
            {/* <QuotationMark className="absolute right-3 top-2.5"/> */}
            <em className="relative z-10 text-primary-container-default">{props.children}</em>
          </span>
        );
      },
      span(props) {
        return <span className="font-bold text-primary-container-default">{props.children}</span>;
      },
      strong(props) {
        return (
          <strong className="bg-container-secondary px-0.5 text-primary-container-default">{props.children}</strong>
        );
      },
      table(props) {
        return (
          <div className="overflow-x-auto px-1">
            <table className="mb-10 table-auto border-collapse overflow-hidden rounded-[16px] border-hidden py-4 text-landing-base shadow-md">
              {props.children}
            </table>
          </div>
        );
      },
      th(props) {
        return (
          <th className="border-modal-stroke-default bg-container-secondary border p-2.5 text-left font-bold text-primary-container-default">
            {props.children}
          </th>
        );
      },
      tr(props) {
        return <tr className="odd:bg-white even:bg-bg-base">{props.children}</tr>;
      },
      td(props) {
        return (
          <td className="border-modal-stroke-default text-primary-on-bg border p-2.5 align-top font-normal first:font-bold">
            {props.children}
          </td>
        );
      },
      blockquote: (props) => (
        <blockquote className="ml-0.5 border-l-[6px] border-l-primitive-purple-500 pl-2.5 text-h5 font-normal !text-primitive-grey-950">
          {props.children}
        </blockquote>
      ),
    }}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
    rehypePlugins={[rehypeRaw, rehypePluginAddingIndex]}
    remarkPlugins={[remarkGfm]}
  >
    {children}
  </ReactMarkdown>
);

const pStyle = {
  small: "text-base leading-6",
  normal: "text-lg leading-[1.75rem]",
  large: "text-xl leading-[135%]",
};

const componentFontSizes: {
  [key in keyof Components]: {
    [key in FontScale]: string;
  };
} = {
  a: pStyle,
  h1: {
    small: "text-4xl",
    normal: "text-5xl",
    large: "text-6xl",
  },
  h2: {
    small: "text-3xl",
    normal: "text-4xl",
    large: "5xl",
  },
  h3: {
    small: "text-3xl",
    normal: "text-4xl",
    large: "text-5xl",
  },
  h4: {
    small: "text-2xl",
    normal: "text-3xl",
    large: "text-4xl",
  },
  h5: {
    small: "text-2xl",
    normal: "text-3xl",
    large: "text-4xl",
  },
  h6: {
    small: "text-2xl",
    normal: "text-3xl",
    large: "text-4xl",
  },
  p: pStyle,
  ul: {
    small: "text-sm",
    normal: "text-base",
    large: "text-lg",
  },
  ol: {
    small: "text-sm",
    normal: "text-base",
    large: "text-lg",
  },
  li: pStyle,
  em: pStyle,
  span: pStyle,
  strong: pStyle,
  th: pStyle,
  td: pStyle,
  blockquote: {
    small: "text-sm",
    normal: "text-base",
    large: "text-lg",
  },
};

export const QuinoMarkdownSummary = ({ children = "" }: { children?: string }) => {
  const [{ size }] = useTypographyConfig();

  return (
    <ReactMarkdown
      className="overflow-x-auto overflow-y-hidden text-primary"
      components={{
        a: (props) => (
          <a
            className={cn(
              "visited:text-primary-purple-800 font-bold text-primary-purple underline",
              componentFontSizes?.a?.[size],
            )}
            {...props}
          />
        ),
        h1(props: ExtendedHeading) {
          return (
            <h1
              className={cn(
                "scroll-mt-8 pb-4 text-landing-h1 font-semibold text-primitive-purple-950 md:scroll-mt-12 md:pb-6",
                componentFontSizes?.h1?.[size],
              )}
              id={`heading-${props.index}`}
            >
              {props.children}
            </h1>
          );
        },
        h2(props: ExtendedHeading) {
          return (
            <h2
              className={cn(
                "scroll-mt-8 pb-4 text-h2  font-semibold text-primitive-purple-950 md:scroll-mt-12 md:text-[2rem]/[2.52rem]",
                componentFontSizes?.h2?.[size],
              )}
              id={`heading-${props.index}`}
            >
              {props.children}
            </h2>
          );
        },
        h3(props: ExtendedHeading) {
          return (
            <h3
              className={cn(
                "scroll-mt-8 pb-4 text-h3 font-semibold text-primitive-purple-950 md:scroll-mt-12",
                componentFontSizes?.h3?.[size],
              )}
              id={`heading-${props.index}`}
            >
              {props.children}
            </h3>
          );
        },
        h4(props: ExtendedHeading) {
          return (
            <h4
              className={cn(
                "scroll-mt-8 pb-4 text-landing-h4 font-semibold text-primitive-purple-950 md:scroll-mt-12",
                componentFontSizes?.h4?.[size],
              )}
              id={`heading-${props.index}`}
            >
              {props.children}
            </h4>
          );
        },
        h5(props: ExtendedHeading) {
          return (
            <h4
              className={cn(
                "scroll-mt-8 pb-4 text-lg font-semibold text-primitive-purple-950 md:scroll-mt-12",
                componentFontSizes?.h5?.[size],
              )}
              id={`heading-${props.index}`}
            >
              {props.children}
            </h4>
          );
        },
        h6(props: ExtendedHeading) {
          return (
            <h4
              className={cn(
                "scroll-mt-8 pb-4 text-lg font-semibold text-primitive-purple-950 md:scroll-mt-12",
                componentFontSizes?.h6?.[size],
              )}
              id={`heading-${props.index}`}
            >
              {props.children}
            </h4>
          );
        },
        p(props) {
          return (
            <p className={cn("mb-4 text-landing-base-adp text-primary last:mb-0", componentFontSizes?.p?.[size])}>
              {props.children}
            </p>
          );
        },
        ul(props) {
          return (
            <ul
              className={cn(
                "ml-6 list-disc text-primary marker:font-bold marker:text-primary-container-default",
                componentFontSizes?.ul?.[size],
              )}
            >
              {props.children}
            </ul>
          );
        },
        ol(props) {
          return (
            <ol
              className={cn(
                "ml-6 list-decimal pl-0.5 marker:font-bold marker:text-primary-container-default",
                componentFontSizes?.ul?.[size],
              )}
            >
              {props.children}
            </ol>
          );
        },
        li(props) {
          return (
            <li className={cn("pb-3 text-primitive-grey-800", componentFontSizes?.li?.[size])}>{props.children}</li>
          );
        },
        em(props) {
          return (
            <span className="relative mt-6 block rounded-lg bg-primitive-purple-100 p-3">
              {/* <QuotationMark className="absolute right-3 top-2.5"/> */}
              <em className={cn("relative z-10 text-primary-container-default", componentFontSizes?.em?.[size])}>
                {props.children}
              </em>
            </span>
          );
        },
        span(props) {
          return (
            <span className={cn("font-bold text-primary-container-default", componentFontSizes?.span?.[size])}>
              {props.children}
            </span>
          );
        },
        strong(props) {
          return (
            <strong
              className={cn(
                "bg-container-secondary px-0.5 font-bold text-primary-container-default",
                componentFontSizes?.strong?.[size],
              )}
            >
              {props.children}
            </strong>
          );
        },
        table(props) {
          return (
            <div className="overflow-x-auto px-1">
              <table className="mb-10 table-auto border-collapse overflow-hidden rounded-[16px] border-hidden py-4 text-landing-base shadow-md">
                {props.children}
              </table>
            </div>
          );
        },
        th(props) {
          return (
            <th
              className={cn(
                "border-modal-stroke-default bg-container-secondary border p-2.5 text-left font-bold text-primary-container-default",
                componentFontSizes?.th?.[size],
              )}
            >
              {props.children}
            </th>
          );
        },
        tr(props) {
          return (
            <tr className={cn("odd:bg-white even:bg-bg-base", componentFontSizes?.tr?.[size])}>{props.children}</tr>
          );
        },
        td(props) {
          return (
            <td
              className={cn(
                "border-modal-stroke-default text-primary-on-bg border p-2.5 align-top font-normal first:font-bold",
                componentFontSizes?.td?.[size],
              )}
            >
              {props.children}
            </td>
          );
        },
        blockquote: (props) => (
          <blockquote
            className={cn(
              "ml-0.5 border-l-[6px] border-l-primitive-purple-500 pl-2.5 text-h5 font-normal !text-primitive-grey-950",
              componentFontSizes?.blockquote?.[size],
            )}
          >
            {props.children}
          </blockquote>
        ),
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
      rehypePlugins={[rehypeRaw, rehypePluginAddingIndex]}
      remarkPlugins={[remarkGfm]}
    >
      {children}
    </ReactMarkdown>
  );
};

export const IneligibleUser = () => {
  return (
    <article className="mt-6 flex w-full flex-col items-center gap-6 px-6">
      <Divider />
      <Typography asChild size="small">
        <p className="text-center">
          This feature is accessible only for subscribers. Upgrade your plan to gain access to your Full Document
          Summaries.
        </p>
      </Typography>
      <span>
        <PlanButton onlyButton text="Upgrade Plan" />
      </span>
    </article>
  );
};
