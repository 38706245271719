import { useEffect, useMemo, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useDocumentSummaryPaginated } from "@/api/document-summary.ts";
import LetterSizePopover from "@/components/document/common/letter-size-popover.tsx";
import ViewerPanelFooter from "@/components/document/common/viewer-panel-footer.tsx";
import ExportSummaryToPdfLink from "@/components/document/summary/export-summary-to-pdf-link.tsx";
import { MagnifierIcon } from "@/components/icons/summary";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, ChapterSelectorTrigger } from "@/components/ui/select";
import { CommandPopoverIcon } from "@/components/v3/icons";
import { useCurrentDocumentTabEntity } from "@/service/hooks/react-router.ts";
import { mixpanelTrack } from "@/service/mixpanel";
import { type SummaryResponse } from "@/types/schemas";

import { ReadAloudPopover } from "./read-aloud-popover";

const chapterType = z.object({
  chapter: z.string(),
});

type ChapterForm = z.infer<typeof chapterType>;

const ChaptersForm = ({ summary }: { summary: SummaryResponse }) => {
  const form = useForm<ChapterForm>({
    resolver: zodResolver(chapterType),
    defaultValues: {
      chapter: summary.chapterIdOrderedList[0],
    },
  });

  const chapter = form.watch("chapter");

  useEffect(() => {
    if (chapter) {
      const target = document.getElementById(`target-${chapter}`);
      if (!target) return;
      const parent = target.parentNode as HTMLElement;
      parent.scrollTop = target.offsetTop - 40;
      target?.scrollIntoView({ behavior: "smooth", inline: "start", block: "start" });
    }
  }, [chapter]);

  const [searchQuery, setSearchQuery] = useState("");

  const filteredChapters = summary?.chapterIdOrderedList?.filter((chapter) =>
    summary.chapterIdNamePairs[chapter].chapterName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const chapterNamesList = useMemo(() => {
    return (
      <div className="mt-14 overflow-y-auto">
        {filteredChapters?.map((chapter) => (
          <SelectItem className="font-jakarta text-text-active" key={chapter} value={chapter}>
            {summary.chapterIdNamePairs[chapter].chapterName}
          </SelectItem>
        ))}
      </div>
    );
  }, [filteredChapters, summary.chapterIdNamePairs]);

  return (
    <Form {...form}>
      <form className="flex flex-1 items-center justify-center" onSubmit={() => {}}>
        <FormField
          control={form.control}
          name="chapter"
          render={({ field }) => (
            <FormItem className="w-full">
              <Select
                disabled={summary?.chapterIdOrderedList.length <= 1}
                value={field.value}
                onValueChange={(e) => {
                  field.onChange(e);
                  mixpanelTrack("fds_section_selection_button_clicked");
                }}
              >
                <FormControl className="overflow-hidden bg-bg-layer1">
                  <ChapterSelectorTrigger className="flex h-full w-full items-center justify-center gap-2 rounded-none border-none px-6 py-4 font-jakarta text-xs font-bold text-text-link-active-onBg hover:bg-container-tertiary">
                    <CommandPopoverIcon />
                    Jump to chapter
                  </ChapterSelectorTrigger>
                </FormControl>
                <SelectContent className="ml-10 h-[230px] w-[350px] rounded-xl p-2">
                  <div className="fixed top-0 z-10 w-full bg-white px-2 pb-4 pt-2">
                    <MagnifierIcon className="absolute left-4 top-5" />
                    <Input
                      className="rounded-lg border-stroke-tertiary-on-bg p-2 pl-7 font-jakarta text-secondary-onBg"
                      placeholder="Search"
                      type="search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  {chapterNamesList}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
};

const SummaryFooter = () => {
  const { data: currentDocument } = useCurrentDocumentTabEntity();
  const documentId = currentDocument?.id;
  const { data: summary } = useDocumentSummaryPaginated(documentId);
  return (
    <ViewerPanelFooter>
      {summary ? <ChaptersForm summary={summary} /> : <section className="flex-1" />}
      <LetterSizePopover />
      {summary ? <ReadAloudPopover summary={summary} /> : <section className="flex-1" />}
      {summary && summary.summarizationStatus === "SUMMARIZED" && documentId && (
        <ExportSummaryToPdfLink documentId={documentId} summary={summary} />
      )}
    </ViewerPanelFooter>
  );
};

export default SummaryFooter;
