import React, { useCallback, useEffect, useMemo, useState } from "react";

import { createId } from "@paralleldrive/cuid2";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { UploadIcon, X } from "lucide-react";
import { type FileRejection } from "react-dropzone";
import { useNavigate } from "react-router-dom";

import { useAddDocumentToStore, useFiles } from "@/api/resources";
import { useCurrentWorkspace } from "@/api/workspace.ts";
import { BACKEND_URL, FILE_SIZE_UPLOAD_LIMIT, ResourceCardType, ROUTES } from "@/assets/constants/constants";
import { ModalKeys } from "@/assets/constants/modal";
import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ToastActionBase } from "@/components/ui/toast.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import {
  ArrowUpRight,
  CheckCircleFilled,
  DeleteIcon,
  DocumentIconFilled,
  DocumentUploadStepper,
  GoogleDrivePicker,
  OneDrivePicker,
  ProjectSelector,
  SelectCreateWorkspace,
} from "@/components/v3";
import DeniedIcon from "@/components/v3/icons/common/denied-icon.tsx";
import { getAccessToken } from "@/firebase";
import { useAuthUserData } from "@/firebase/hooks";
import { customGAEventSender } from "@/service";
import { useFileUploadDropzone } from "@/service/hooks/documents.ts";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useIsFreeUser, useIsInLimitedFreeUserGroup } from "@/service/hooks/settings.ts";
import { useUpgradePlanModal } from "@/service/library.ts";
import {
  type ExistingProject,
  type FileData,
  type NewProject,
  type ProjectSelectionPayload,
  UploadType,
} from "@/types/app.ts";
import { type FileUploadState, type UploadState } from "@/types/interfaces/library";
import { type UserOnboardingDataSchema } from "@/types/schemas";
import type { WorkspaceListItem } from "@/types/schemas/api/workspaceEntity.ts";
import { sleep, useCurrentProjectData } from "@/utils";
import { useModalState } from "@/zustand/slices/modal-slice";

import DropzoneIllustration from "../../icons/document/dropzone-illustration.tsx";

const initialState: UploadState = {};

const determineInitialUploadSate = (file: File, isFreeUser: boolean): FileUploadState => {
  if (isFreeUser && file.size >= FILE_SIZE_UPLOAD_LIMIT.FREE) {
    return {
      loading: false,
      value: -1,
      status: "ERROR",
      message: `This document size is too large, try to keep it under 50 MB.`,
    };
  }
  if (file.size >= FILE_SIZE_UPLOAD_LIMIT.PAYING) {
    return {
      loading: false,
      status: "ERROR",
      value: -1,
      message: `This document size is too large, try to keep it under 100 MB.`,
    };
  }
  return {
    loading: false,
    value: 0,
    message: "",
    status: "NO_STATE",
  };
};

function FileUploadStateView(props: { uploadState: UploadState; fileData: FileData; onDeleteClick: () => void }) {
  const fileName = useMemo(
    () => (
      <p className="flex max-w-[280px] flex-1 flex-row overflow-hidden whitespace-nowrap text-base font-bold text-primary dark:text-white">
        <span className="max-w-full truncate">
          {props.fileData.file.name.substring(0, props.fileData.file.name.lastIndexOf("."))}
        </span>
        <span>
          {props.fileData.file.name.substring(
            props.fileData.file.name.lastIndexOf("."),
            props.fileData.file.name.length,
          )}
        </span>
      </p>
    ),
    [props.fileData.file.name],
  );

  return (
    <React.Fragment>
      {props.uploadState[props.fileData.id].status === "NO_STATE" && (
        <div className="flex w-full items-center justify-between gap-4 overflow-hidden">
          <section className="flex flex-row items-center gap-4">
            <span className="h-10 w-10 rounded-lg bg-container-tertiary p-3 dark:bg-white/10">
              <DocumentIconFilled className="h-4 w-4" />
            </span>
            {fileName}
          </section>
          <Button
            id={`remove-document-from-upload-list-button-${props.fileData.id}`}
            size="icon"
            variant="destructive"
            onClick={(e) => {
              e.stopPropagation();
              props.onDeleteClick();
            }}
          >
            <DeleteIcon />
          </Button>
        </div>
      )}
      {props.uploadState[props.fileData.id].status === "UPLOADING" && (
        <div className="flex w-full items-center justify-between gap-4 overflow-hidden">
          <section className="flex flex-row items-center gap-4">
            <span className="h-10 w-10 rounded-lg bg-container-tertiary p-3 dark:bg-white/10">
              <DocumentIconFilled className="h-4 w-4" />
            </span>
            {fileName}
          </section>
          <Button
            className="opacity-40"
            id={`remove-document-from-upload-list-button-${props.fileData.id}`}
            size="icon"
            variant="destructive"
          >
            <DeleteIcon />
          </Button>
        </div>
      )}
      {props.uploadState[props.fileData.id].status === "DONE" && (
        <div className="flex w-full items-center justify-between gap-4 overflow-hidden">
          <section className="flex flex-row items-center gap-4">
            <span className="h-10 w-10 rounded-lg bg-container-tertiary p-3 dark:bg-white/10">
              <DocumentIconFilled className="h-4 w-4" />
            </span>
            {fileName}
          </section>
          <Button id={`remove-document-from-upload-list-button-${props.fileData.id}`} size="icon" variant="destructive">
            <DeleteIcon />
          </Button>
        </div>
      )}
      {props.uploadState[props.fileData.id].status === "ERROR" && (
        <div className="flex w-full items-center justify-between gap-4 overflow-hidden">
          <section className="flex flex-row items-center gap-4">
            <span className="h-10 w-10 rounded-lg bg-primitive-red-50 p-3 dark:bg-white/10">
              <DeniedIcon className="h-4 w-4" />
            </span>
            {fileName}
            <p>{props.uploadState[props.fileData.id].message}</p>
          </section>
          <Button id={`remove-document-from-upload-list-button-${props.fileData.id}`} size="icon" variant="destructive">
            <DeleteIcon />
          </Button>
        </div>
      )}
    </React.Fragment>
  );
}

const useSelectedProjectAndWorkspace = () => {
  const { data: workspaceData } = useCurrentWorkspace();
  const { data: projectData } = useCurrentProjectData();

  const [state, setState] = useState<ProjectSelectionPayload>(() => {
    if (workspaceData && projectData) {
      const exisingProject: ExistingProject = {
        hasDefaultWorkspace: true,
        hasDefaultProject: true,
        uploadType: UploadType.EXISTING_PROJECT,
        projectId: projectData.id,
        workspaceId: workspaceData.id,
        workspaceName: workspaceData.name,
        projectName: projectData.name,
      };
      return exisingProject;
    }
    if (workspaceData && !projectData) {
      const newProject: NewProject = {
        hasDefaultWorkspace: true,
        hasDefaultProject: false,
        uploadType: UploadType.NEW_PROJECT,
        workspaceId: workspaceData.id,
        workspaceName: workspaceData.name,
        projectName: undefined,
      };
      return newProject;
    }
    return {
      uploadType: UploadType.NEW_WORKSPACE,
      hasDefaultProject: false,
      hasDefaultWorkspace: false,
      projectName: undefined,
      workspaceName: undefined,
    };
  });

  const isValid = useMemo<boolean>(() => {
    if (state.uploadType === UploadType.NEW_WORKSPACE) {
      if (!state.projectName || !state.projectName) return false;
      return true;
    }
    if (state.uploadType === UploadType.NEW_PROJECT) {
      if (!state.projectName) return false;
      return true;
    }
    if (state.uploadType === UploadType.EXISTING_PROJECT) {
      if (!state.projectId) return false;
      return true;
    }
    return false;
  }, [state]);

  useEffect(() => {
    if (projectData && workspaceData)
      setState({
        workspaceId: projectData.workspaceId,
        projectId: projectData.id,
        hasDefaultProject: true,
        hasDefaultWorkspace: true,
        uploadType: UploadType.EXISTING_PROJECT,
        projectName: projectData.name,
        workspaceName: workspaceData.name,
      });
    else if (workspaceData)
      setState({
        workspaceId: workspaceData.id,
        hasDefaultProject: false,
        hasDefaultWorkspace: true,
        uploadType: UploadType.NEW_PROJECT,
        workspaceName: workspaceData.name,
      });
    else
      setState({
        hasDefaultProject: false,
        hasDefaultWorkspace: false,
        uploadType: UploadType.NEW_WORKSPACE,
      });
  }, [projectData, workspaceData]);

  return {
    setProjectSelectionState: setState,
    projectSelectionState: state,
    isValid,
  };
};

const CreateDocumentModal = () => {
  const [, setUpdatePlanModalState] = useUpgradePlanModal();
  const [{ isOpen, toOpen, initialFilePayload }, setModalState] = useModalState(ModalKeys.DOCUMENT_UPLOAD_OPEN);
  const isInLimitedFreeUserGroup = useIsInLimitedFreeUserGroup();
  const [inputFiles, setInputFiles] = useState<FileData[]>([]);
  const [uploadState, setUploadState] = useState<UploadState>(initialState);
  const { data: userData } = useAuthUserData();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const addResourceAsChild = useAddDocumentToStore();
  const { refetch: refetchWorkspace } = useCurrentWorkspace();
  const { refetch: refetchFiles, data: filesData } = useFiles();
  const isFree = useIsFreeUser();
  const isUploadAllowed = useMemo(
    () => !Object.values(uploadState).some((value) => value.status !== "NO_STATE"),
    [uploadState],
  );

  const [loading, setLoading] = useState(false);

  const isUploading = useMemo(
    () => Object.values(uploadState).some((value) => value.status === "UPLOADING"),
    [uploadState],
  );

  const { projectSelectionState, setProjectSelectionState, isValid } = useSelectedProjectAndWorkspace();
  const currentWorkspaceId = useCurrentWorkspaceId();

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (isUploading) return;
      setModalState({
        isOpen: open,
      });
    },
    [setModalState, isUploading],
  );

  const uploadFilesToBackend = async (inputFiles: FileData[]) => {
    if (!inputFiles || inputFiles.length === 0) return;
    const formData = new FormData();
    const stateCache = JSON.parse(JSON.stringify(projectSelectionState)) as ProjectSelectionPayload;
    const uploadType = projectSelectionState.uploadType;
    const projectName = projectSelectionState.projectName;
    if (uploadType === UploadType.EXISTING_PROJECT) {
      formData.set("upload_type", "EXISTING_PROJECT");
      formData.set("project_id", projectSelectionState.projectId);
      formData.set("workspace_id", projectSelectionState.workspaceId);
    } else if (uploadType === UploadType.NEW_PROJECT) {
      if (!projectSelectionState.projectName) return;
      formData.set("upload_type", "NEW_PROJECT");
      formData.set("project_name", projectSelectionState.projectName);
      formData.set("workspace_id", projectSelectionState.workspaceId);
    } else if (uploadType === UploadType.NEW_WORKSPACE) {
      if (!projectSelectionState.projectName) return;
      console.log(projectSelectionState);
      formData.set("upload_type", "NEW_WORKSPACE");
      formData.set("project_name", projectSelectionState.projectName);
      formData.set("workspace_name", projectSelectionState.workspaceName ?? "");
    }

    inputFiles.map((fileData, index) => {
      const fileId = fileData.id;
      const name = fileData.file.name.substring(0, fileData.file.name.lastIndexOf("."));
      const commonMetadata = {
        id: fileId,
        name: name,
      };
      formData.append("files", inputFiles[index].file);
      formData.append("metadata_list", JSON.stringify(commonMetadata));

      setUploadState((state) => ({
        ...state,
        [fileData.id]: {
          loading: true,
          value: 0,
          status: "UPLOADING",
          message: "Uploading!",
        },
      }));
    });
    try {
      const response = await axios.post(`${BACKEND_URL}/document-converter/convert`, formData, {
        headers: { Authorization: `Bearer ${await getAccessToken()}` },
        onUploadProgress: (progress) => {
          if (!progress.total) return;
          const percent = (progress.loaded / progress.total) * 100;
          setUploadState((state) => {
            inputFiles.forEach((file) => {
              if (Object.hasOwn(state, file.id) && state[file.id].status === "UPLOADING")
                state[file.id] = {
                  loading: true,
                  status: "UPLOADING",
                  value: percent,
                  message: "Uploading!",
                };
            });
            return { ...state };
          });
        },
      });
      const fileUploadResponseData = response.data as {
        id: string;
        status: "SUCCESS" | "ERROR";
        projectId?: string;
        workspaceId?: string;
      }[];
      if (projectSelectionState.hasDefaultWorkspace) await refetchWorkspace();

      const newProjectId =
        fileUploadResponseData[0].projectId ??
        (projectSelectionState.uploadType === UploadType.EXISTING_PROJECT ? projectSelectionState.projectId : "");

      const newWorkspaceId =
        fileUploadResponseData[0].workspaceId ?? (uploadType === UploadType.NEW_WORKSPACE ? "" : currentWorkspaceId);

      await sleep(5000);

      fileUploadResponseData.map((fileData) => {
        if (fileData.status === "SUCCESS") {
          setUploadState((state) => ({
            ...state,
            [fileData.id]: {
              loading: false,
              value: -1,
              status: "DONE",
              message: "Upload done!",
            },
          }));
        } else {
          setUploadState((state) => ({
            ...state,
            [fileData.id]: {
              loading: false,
              value: -1,
              status: "ERROR",
              message: "Couldn't convert the document into a PDF!",
            },
          }));
        }
      });
      fileUploadResponseData.forEach((responseFileStatus, index) => {
        const name = inputFiles[index].file.name.substring(0, inputFiles[index].file.name.lastIndexOf("."));
        queryClient.setQueryData<UserOnboardingDataSchema>([QUERY_KEYS.USER_ONBOARDING_DATA, userData?.uid], (prev) =>
          prev ? { ...prev, documentUploaded: true } : undefined,
        );
        if (responseFileStatus.status === "SUCCESS")
          addResourceAsChild({
            id: responseFileStatus.id,
            elementType: ResourceCardType.DOCUMENT_ELEMENT,
            dateCreated: new Date().toISOString(),
            favourite: false,
            name: name,
            projectId: stateCache.uploadType === UploadType.EXISTING_PROJECT ? stateCache.projectId : "",
            userId: userData?.uid ?? "",
          });
      });

      if (uploadType === UploadType.NEW_PROJECT)
        queryClient.setQueryData<WorkspaceListItem[]>([userData?.uid, QUERY_KEYS.WORKSPACES], (prev) => {
          if (!prev) return prev;

          const newData = [...prev];

          const workspaceIdx = newData.findIndex((item) => item.id === newWorkspaceId);
          if (workspaceIdx !== -1) {
            newData[workspaceIdx] = {
              ...newData[workspaceIdx],
              numOfProjects: newData[workspaceIdx].numOfProjects + 1,
            };
          }

          return newData;
        });

      if (toOpen === "summary") {
        navigate(
          `${ROUTES.DOCUMENTS}/${newWorkspaceId}/${newProjectId}?document=${fileUploadResponseData[0].id}&note-tab=summary`,
        );
        return;
      }

      if (toOpen === "chat") {
        navigate(
          `${ROUTES.DOCUMENTS}/${newWorkspaceId}/${newProjectId}?document=${fileUploadResponseData[0].id}&note-tab=chat`,
        );
        return;
      }

      if (inputFiles.length === 1) {
        toast({
          title: `${inputFiles[0].file.name} is uploaded`,
          icon: <CheckCircleFilled />,
          action: (
            <ToastActionBase asChild altText="Open file">
              <Button
                size="sm"
                variant="secondary"
                onClick={() =>
                  navigate(
                    `${ROUTES.DOCUMENTS}/${newWorkspaceId}/${newProjectId}?document=${fileUploadResponseData[0].id}`,
                  )
                }
              >
                Open{" "}
                <span className="h-4 w-4">
                  <ArrowUpRight />
                </span>
              </Button>
            </ToastActionBase>
          ),
        });
      } else {
        toast({
          title: `You've uploaded ${inputFiles.length} documents to ${projectName}`,
          icon: <CheckCircleFilled />,
        });
      }
    } catch (e) {
      console.log(e);
      toast({
        title: `Failed to upload document`,
        variant: "destructive",
      });
      inputFiles.map((fileData) => {
        setUploadState((state) => ({
          ...state,
          [fileData.id]: {
            loading: false,
            value: -1,
            status: "ERROR",
            message: "Upload failed please try again!",
          },
        }));
      });
    }
  };

  const disableUploadFile = useMemo(() => {
    if (inputFiles.length === 0) return true;
    return Object.keys(uploadState).some((item) => uploadState[item].status !== "NO_STATE");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFiles.length, uploadState]);

  const projectSelectorDisabled = useMemo(() => {
    if (projectSelectionState.uploadType === UploadType.NEW_WORKSPACE) {
      if (!projectSelectionState.workspaceName) return true;
    }
    return false;
  }, [projectSelectionState.uploadType, projectSelectionState.workspaceName]);

  const selectors = useMemo(() => {
    if (!projectSelectionState.hasDefaultWorkspace) {
      const possibleWorkspaceId = (projectSelectionState as unknown as { workspaceId?: string }).workspaceId;
      return (
        <>
          <section className="flex flex-col gap-2">
            <p className="text-xs font-bold text-primary">Upload to...</p>
            <SelectCreateWorkspace
              allowOther
              onCallback={(value) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setProjectSelectionState((prev) => {
                  return { ...prev, ...value };
                });
              }}
            />
          </section>
          <section className="flex flex-col gap-2">
            <p className="text-xs font-bold text-primary">Select project</p>
            <ProjectSelector
              allowOther
              disabled={projectSelectorDisabled}
              state={projectSelectionState}
              workspaceId={possibleWorkspaceId}
              onCallback={(value) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setProjectSelectionState((prev) => {
                  const type =
                    prev.uploadType === UploadType.NEW_WORKSPACE && value.uploadType === UploadType.NEW_PROJECT
                      ? UploadType.NEW_WORKSPACE
                      : value.uploadType;

                  return { ...prev, ...value, uploadType: type };
                });
              }}
            />
          </section>
        </>
      );
    }
    if (projectSelectionState.hasDefaultWorkspace && !projectSelectionState.hasDefaultProject)
      return (
        <section className="flex flex-col gap-2">
          <p className="text-xs font-bold text-primary">Upload to...</p>
          <ProjectSelector
            allowOther
            state={projectSelectionState}
            onCallback={(value) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setProjectSelectionState((prev) => {
                return { ...prev, ...value };
              });
            }}
          />
        </section>
      );
    return null;
  }, [projectSelectionState, projectSelectorDisabled, setProjectSelectionState]);

  async function uploadFiles(): Promise<void> {
    if (inputFiles.length === 0 || disableUploadFile) return;
    setLoading(true);
    const promises: Array<Promise<unknown>> = [];
    promises.push(uploadFilesToBackend(inputFiles));
    await Promise.all(promises);

    handleClose();
    setLoading(false);

    setTimeout(() => {
      if (
        filesData &&
        filesData.filter((item) => item.elementType === ResourceCardType.NOTE_ELEMENT).length === 0 &&
        projectSelectionState.hasDefaultProject
      )
        void refetchFiles();
    }, 2000);
  }

  const addToExistingFileList = useCallback(
    (fileList: File[]) => {
      const uploadStateUpdate: UploadState = {};
      if (fileList.length + inputFiles.length > 3) {
        toast({
          title: "Too many files selected, you can upload 3 files at once!",
          variant: "destructive",
        });
        return;
      }
      const inputFileData: FileData[] = fileList.map((item) => {
        const cuid = createId().toLowerCase();
        return {
          id: cuid,
          file: item,
        };
      });
      inputFileData.forEach((item) => {
        uploadStateUpdate[item.id] = determineInitialUploadSate(item.file, isInLimitedFreeUserGroup);
      });
      setUploadState(() => ({
        ...uploadStateUpdate,
      }));
      setInputFiles((prev) => [...prev, ...inputFileData]);
    },
    [inputFiles.length, isInLimitedFreeUserGroup],
  );

  function removeItemFromList(id: string): void {
    //setUploadState((prev) => {...prev, id: undefined});
    setInputFiles((prev) => prev.filter((item) => item.id !== id));
  }

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (acceptedFiles) addToExistingFileList(acceptedFiles);
      if (rejectedFiles.length > 0) {
        if (isFree) {
          setUpdatePlanModalState({ open: true });
        } else
          toast({
            title: "Some files were rejected",
            description: rejectedFiles
              .map((item) => `${item.file.name} (${rejectedFiles[0].errors.map((item) => item.message).join(", ")})`)
              .join(", "),
            variant: "destructive",
          });
      }
      customGAEventSender("document", "drag_n_drop_upload");
    },
    [addToExistingFileList, isFree, setUpdatePlanModalState],
  );
  const title = useMemo(() => {
    if (toOpen === "chat") return "Chat with your files";
    if (toOpen === "summary") return "Summarize document";
    return "Add documents";
  }, [toOpen]);

  const { getRootProps, getInputProps, isDragActive, open } = useFileUploadDropzone({
    multiple: toOpen === undefined,
    onDrop: onDrop,
    noClick: true,
  });

  const handleClose = useCallback(() => {
    setUploadState(initialState);
    setInputFiles([]);
    setModalState({ isOpen: false });
  }, [setUploadState, setInputFiles, setModalState]);

  const onClickSubscription = useCallback(() => {
    handleClose();
    setUpdatePlanModalState({ open: true });
  }, [handleClose, setUpdatePlanModalState]);

  useEffect(() => {
    if (initialFilePayload && isOpen) {
      addToExistingFileList(initialFilePayload.map((item) => item.file));
    } else if (!isOpen) {
      setInputFiles([]);
      setUploadState(initialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, setUploadState, setInputFiles]);

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent hideClose className="h-full w-full max-w-none gap-12 border-0 bg-transparent" {...getRootProps()}>
        <section className="fixed left-[50%] top-[50%] z-[300] grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-12 border border-neutral-200 bg-white p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] dark:border-primary-dark dark:bg-primary-dark sm:rounded-2xl md:w-full">
          <DialogHeader className="space-y-4">
            <DialogTitle>{title}</DialogTitle>
            <DialogDescription className="text-xs data-[hidden=true]:hidden" data-hidden={!isInLimitedFreeUserGroup}>
              Upload .pdf files, maximum of 25 MB of file size.{" "}
              <span className="cursor-pointer font-bold text-default underline" onClick={onClickSubscription}>
                Subscribe now for unlimited uploads and various formats
              </span>
            </DialogDescription>
          </DialogHeader>
          <div className="flex w-full flex-col gap-4">
            <div className="flex gap-2 data-[hidden=true]:hidden" data-hidden={inputFiles.length !== 0}>
              <GoogleDrivePicker className="w-1/2" />
              <OneDrivePicker className="w-1/2" />
            </div>
            <section className="flex cursor-pointer flex-col gap-4" onClick={() => open()}>
              <input {...getInputProps()} />
              <section
                className="flex min-h-[7.5rem] w-full cursor-pointer flex-row items-center justify-center gap-4 rounded-lg border border-dashed border-primitive-purple-pale-100 data-[hidden=true]:hidden"
                data-hidden={inputFiles.length !== 0}
              >
                <UploadIcon className="stroke-default " />
                <p className="text-xs font-bold text-primary">Select from device...</p>
              </section>
              {inputFiles.length > 0 && (
                <div className="flex w-full flex-col gap-4">
                  {inputFiles.map((fileData) => (
                    <FileUploadStateView
                      fileData={fileData}
                      key={fileData.id}
                      uploadState={uploadState}
                      onDeleteClick={() => {
                        removeItemFromList(fileData.id);
                      }}
                    />
                  ))}
                </div>
              )}
              <Button
                className="w-full data-[hidden=true]:hidden data-[is-uploading=true]:opacity-40"
                data-hidden={inputFiles.length === 0}
                data-is-uploading={isUploading}
                variant="secondary"
              >
                <UploadIcon className="h-4 w-4 stroke-default" />
                Select more from device
              </Button>
            </section>
            {selectors}
          </div>
          <DialogFooter>
            <Button size="sm" type="button" variant="ghost" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className={`${isUploading && "disabled:opacity-100"}`}
              disabled={disableUploadFile || !isUploadAllowed || loading || isUploading || !isValid}
              size="sm"
              onClick={() => void uploadFiles()}
            >
              Add documents
              <span className="hidden h-4 w-4 data-[is-uploading=true]:inline" data-is-uploading={isUploading}>
                <DocumentUploadStepper className="animate-spin fill-white" />
              </span>
            </Button>
          </DialogFooter>
          <DialogClose className="absolute right-8 top-8 rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-neutral-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-neutral-100 data-[state=open]:text-neutral-500 dark:text-white dark:ring-offset-neutral-950 dark:focus:ring-neutral-300 dark:data-[state=open]:bg-neutral-800 dark:data-[state=open]:text-neutral-400">
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogClose>
          <section
            className="pointer-events-none absolute -left-[2px] -top-[2px] flex h-[calc(100%+4px)] w-[calc(100%+4px)] flex-col items-center justify-center bg-primitive-purple-600 p-4 text-white opacity-0 transition-opacity duration-300 data-[is-drag=true]:opacity-100 sm:rounded-2xl"
            data-is-drag={isDragActive}
          >
            <section>
              <DropzoneIllustration />
            </section>
            <section>Drop it here and let the magic begin!</section>
            <section>Upload documents by dropping them in this window.</section>
          </section>
        </section>
      </DialogContent>
    </Dialog>
  );
};

export default CreateDocumentModal;
