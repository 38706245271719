import { useMemo } from "react";

import { type RootContent } from "mdast";
import { toString } from "mdast-util-to-string";
import { useLocation } from "react-router-dom";
import { remark } from "remark";

import TableOfContents from "@/components/account/table-of-contents";
import { LoadingSpinner } from "@/pages/loading";
import { QuinoMarkdown } from "@/utils/util-components";

import "./table-of-contents.css";

const LegalTemplate = ({
  title,
  data,
  isLoading,
  isError,
}: {
  title: string;
  data: string | undefined;
  isLoading: boolean;
  isError: boolean;
}) => {
  const { pathname } = useLocation();
  const headings = useMemo(() => {
    const content = remark().parse(data);

    // extract headings
    const headings = [] as { value: string; index: string }[];
    let headingCount = 0;

    content.children.forEach((node: RootContent) => {
      if (node.type == "heading") {
        const headingText = toString(node);
        headings.push({ value: headingText, index: `${headingCount}` });
        headingCount++;
      }
    });
    return headings;
  }, [data]);

  if (isError) return null;
  if (isLoading) return <LoadingSpinner className="my-10" />;

  return (
    <div className="no-scrollbar mx-auto mt-20 flex w-[1180px] flex-col gap-12 bg-primitive-purple-pale-50">
      <div className="flex items-start gap-12">
        <div className="flex max-w-[761px] flex-col gap-10">
          <span className="text-landing-h1 font-light text-primitive-purple-950" data-cy="title">
            {title}
          </span>
          <QuinoMarkdown>{data}</QuinoMarkdown>
        </div>
        <div className="sticky top-24 px-4">
          <TableOfContents headings={headings} pathname={pathname.substring(1)} />
        </div>
      </div>
      <div className="absolute right-[-42px] top-[255px] z-10 h-[172px] w-[172px] rounded-full bg-primitive-purple-600/50 blur-[127.5px]"></div>
    </div>
  );
};

export default LegalTemplate;
