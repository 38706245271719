import { useMemo } from "react";

import { useActivityHistory, useRecentlyUsed } from "@/api/user.ts";
import { MaskedNameForFunnyStuff } from "@/components/funny-stuff/google.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { RecentlyUsedDocument } from "@/components/v3";

const RecentlyUsed = () => {
  const { data } = useActivityHistory();
  const { data: recentlyUsed } = useRecentlyUsed();

  const mapped = useMemo(() => data?.pages.flatMap((page) => page), [data]);

  const memoizedList = useMemo(() => {
    return recentlyUsed?.map((item) => (
      <RecentlyUsedDocument
        coverImageUrl={item.documentCoverImageUrl}
        documentId={item.documentId}
        documentName={item.documentName}
        key={`${JSON.stringify(item)}`}
        lastOpen={item.dateLastOpen}
        projectId={item.projectId}
        workspace={item.workspaceName}
        workspaceId={item.workspaceId}
      />
    ));
  }, [recentlyUsed]);

  if (!mapped || mapped.length === 0) return null;

  return (
    <section className="col-span-4">
      <section className="mb-6">
        <Typography className="font-bold " size="large">
          Recently used
        </Typography>
      </section>
      <section className="flex flex-col gap-3">{memoizedList}</section>
      <section className="flex w-full">
        <MaskedNameForFunnyStuff
          format="inline"
          responsive="true"
          slot="9673452910"
          style={{ height: "214px", width: "100%" }}
        />
      </section>
    </section>
  );
};
export default RecentlyUsed;
