import { useCallback, useEffect, useMemo, useState } from "react";

import { FirebaseError } from "@firebase/app";
import { applyActionCode } from "@firebase/auth";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useInterval } from "usehooks-ts";

import { ROUTES } from "@/assets/constants/constants.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { useToast } from "@/components/ui/use-toast.ts";
import { auth, logout } from "@/firebase";
import { useAuthUserData, useUpdateFirebaseUserData } from "@/firebase/hooks.ts";
import Loading from "@/pages/loading";
import { customGAEventSender } from "@/service";
import useAppStateStore from "@/zustand/store.ts";

const FirebaseAction = () => {
  const { toast } = useToast();
  const setModalKey = useAppStateStore((state) => state.setModalKey);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { data, firebaseUser } = useAuthUserData();
  const { mutate: updateUser, isSuccess } = useUpdateFirebaseUserData();
  const [actionChecked, setActionChecked] = useState({ checked: false });

  const handleEmailVerification = useCallback(
    async (actionCode: string): Promise<void> => {
      try {
        await applyActionCode(auth, actionCode);
        if (auth.currentUser) {
          updateUser({ payload: { email_verified: true } });
        }
        customGAEventSender("login", "email_verified");
        if (auth.currentUser)
          toast({
            title: "Thank you for verifying your email",
            variant: "success",
          });
        else {
          toast({
            title: "Thanks for confirming your email! To get started with the app, just log in! 🌟",
            variant: "success",
          });
          setActionChecked({ checked: true });
        }
        setModalKey(ModalKeys.EMAIL_VERIFICATION_MODAL_OPEN, { open: false });
      } catch (e) {
        console.log(e);

        const error = e as FirebaseError;
        if (e instanceof FirebaseError) {
          if (error.code === "auth/invalid-action-code") {
            customGAEventSender("login", "invalid_action_code_used");
            toast({
              title: "Your verification code might be malformed or is invalid. Try again or contact support!",
              variant: "destructive",
            });
            if (auth.currentUser) setModalKey(ModalKeys.EMAIL_VERIFICATION_MODAL_OPEN, { open: true });
          } else if (error.code === "auth/expired-action-code") {
            customGAEventSender("login", "expired_verification_used");
            toast({
              title: "Your verification code might be expired. Try again!",
              variant: "destructive",
            });
            if (auth.currentUser) setModalKey(ModalKeys.EMAIL_VERIFICATION_MODAL_OPEN, { open: true });
          } else if (error.code === "auth/user-disabled") {
            customGAEventSender("login", "user_disabled_verification");
            toast({
              title: "Your account has been disabled!",
              variant: "destructive",
            });
          } else if (error.code === "auth/user-not-found") {
            customGAEventSender("login", "user_not_found");
            toast({
              title: "The user associated with this hasn't been found!",
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: "Something went wrong with the verification please contact support!",
            variant: "destructive",
          });
        }
        logout();
        setActionChecked({ checked: true });
      }
    },
    [setModalKey, toast, updateUser],
  );
  useEffect(() => {
    if (isSuccess && firebaseUser?.emailVerified && data?.email_verified) {
      setActionChecked({ checked: true });
    }
  }, [data?.email_verified, firebaseUser?.emailVerified, isSuccess]);

  const intervalValue = useMemo(
    () => (isSuccess && firebaseUser && !firebaseUser?.emailVerified ? 500 : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firebaseUser, firebaseUser?.emailVerified, isSuccess],
  );
  useInterval(() => {
    void auth.currentUser?.reload();
  }, intervalValue);

  useEffect(() => {
    setActionChecked({ checked: false });

    if (location.pathname.startsWith(ROUTES.FIREBASE_ACTION)) {
      const mode = searchParams.get("mode");
      const oobCode = searchParams.get("oobCode");
      if (mode === "verifyEmail" && oobCode) {
        void handleEmailVerification(oobCode);
      } else if (mode === "resetPassword" && oobCode) {
        navigate(`${ROUTES.RESET}/new-password?oobCode=${oobCode}`);
        setActionChecked({ checked: true });
      }
    } else {
      setActionChecked({ checked: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!actionChecked)
    return (
      <section className="h-full w-full">
        <Loading />
      </section>
    );

  return <Navigate to={ROUTES.LOGIN} />;
};

export default FirebaseAction;
