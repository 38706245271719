import { useCallback, useEffect, useState } from "react";

import PCMPlayer from "pcm-player";
import { isSafari } from "react-device-detect";

import { fetchAudioStream } from "@/api/text2speech";
import { documentDb } from "@/service/dexie.ts";
import { type SummaryResponse } from "@/types/schemas";
import useAppStateStore from "@/zustand/store";

export const useStreamWavFile = () => {
  const [player, setPlayer] = useState<PCMPlayer | null>(null);

  const { selectedParagraphId, selectedVoice, play } = useAppStateStore((state) => ({
    selectedParagraphId: state.selectedParagraphId,
    selectedVoice: state.selectedVoice,
    play: state.play,
  }));

  useEffect(() => {
    if (!isSafari) return;

    if (player) {
      if (play) {
        void player.continue();
      } else if (!play) {
        void player.pause();
      }
    }
  }, [play, player]);

  useEffect(() => {
    if (!isSafari) return;

    let player: PCMPlayer | null = null;
    const controller = new AbortController();
    const signal = controller.signal;

    void (async () => {
      player = new PCMPlayer({
        inputCodec: "Int16",
        channels: 1,
        sampleRate: 24000,
        flushTime: 2000,
        fftSize: 2048,
      });
      player.volume(1.25);
      setPlayer(player);

      const stream = await fetchAudioStream(
        {
          paragraphSummaryId: selectedParagraphId,
          voiceConfig: selectedVoice,
        },
        signal,
      );

      const reader = stream.getReader();

      const readChunk = async () => {
        const { done, value } = await reader.read();
        if (done) {
          return;
        }
        if (value) {
          try {
            const ar16 = new Int16Array(
              value.buffer,
              value.byteOffset,
              value.byteLength / Int16Array.BYTES_PER_ELEMENT,
            );
            player?.feed(ar16);
          } catch (e) {
            console.error(e);
            console.error(value);
          }
        }
        void readChunk();
      };

      void readChunk();
    })();

    return () => {
      setTimeout(() => {
        player?.destroy();
      });
      setTimeout(() => {
        controller.abort();
      });
    };
  }, [selectedParagraphId, selectedVoice]);
};

export const useAudioEffect = (disable = false) => {
  const { selectedParagraphId, selectedVoice, setLoading } = useAppStateStore((state) => ({
    selectedParagraphId: state.selectedParagraphId,
    selectedVoice: state.selectedVoice,
    setLoading: state.setTTSLoading,
  }));

  const { setCache } = useCachedAudioUrl();

  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  useEffect(() => {
    if (disable) return;
    if (!isSafari) return;

    setAudioUrl("");
    setLoading(true);

    let stream: ReadableStream<Uint8Array> | null = null;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchAndSetAudio = async () => {
      try {
        if (!selectedParagraphId) return;
        stream = await fetchAudioStream(
          {
            paragraphSummaryId: selectedParagraphId,
            voiceConfig: selectedVoice,
          },
          signal,
        );

        const reader = stream.getReader();
        const audioChunks: Uint8Array[] = [];

        const readChunk = async () => {
          const { done, value } = await reader.read();
          if (done) {
            const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
            const url = URL.createObjectURL(audioBlob);

            if (audioUrl) {
              setCache(selectedParagraphId, selectedVoice, false, audioBlob);
              URL.revokeObjectURL(audioUrl);
            }

            setAudioUrl(url);
            setLoading(false);
            return;
          }
          if (value) {
            audioChunks.push(value);
          }
          void readChunk();
        };

        void readChunk();
      } catch (error) {
        setLoading(false);
        console.error("Error fetching audio stream:", error);
      }
    };

    void fetchAndSetAudio();

    return () => {
      setTimeout(() => {
        controller.abort();
      });
      void stream?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParagraphId, selectedVoice]);

  return { audioUrl };
};

interface UseAudioProgressionProps {
  audioRef: React.RefObject<HTMLAudioElement>;
  summary?: SummaryResponse;
}

export const useAudioProgression = ({ audioRef, summary }: UseAudioProgressionProps) => {
  const {
    play,
    setPlay,
    selectedChapterIdIndex,
    setSelectedChapterIdIndex,
    setSelectedChapterId,
    setParagraphDetails,
    setNextDisabled,
  } = useAppStateStore((state) => ({
    play: state.play,
    setPlay: state.setPlay,
    selectedChapterIdIndex: state.selectedChapterIdIndex,
    setSelectedChapterIdIndex: state.setSelectedChapterIdIndex,
    selectedChapterId: state.selectedChapterId,
    setSelectedChapterId: state.setSelectedChapterId,
    setParagraphDetails: state.setParagraphDetails,
    setNextDisabled: state.setNextDisabled,
  }));

  useEffect(() => {
    if (summary && audioRef.current && play) {
      const handleAudioEnd = () => {
        if (selectedChapterIdIndex < summary.chapterIdOrderedList.length - 1) {
          const newChapterIndex = selectedChapterIdIndex + 1;
          const newChapterId = summary.chapterIdOrderedList[newChapterIndex];
          setSelectedChapterIdIndex(newChapterIndex);
          setSelectedChapterId(newChapterId);

          setParagraphDetails(summary.paragraphSummariesOfChapter[newChapterId][0]);
        } else {
          setNextDisabled(true);
          setPlay(false);
        }
      };

      audioRef.current.addEventListener("ended", handleAudioEnd);

      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        audioRef.current?.removeEventListener("ended", handleAudioEnd);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    audioRef,
    play,
    selectedChapterIdIndex,
    summary,
    setParagraphDetails,
    setSelectedChapterIdIndex,
    setSelectedChapterId,
    setPlay,
  ]);
};

export const useCachedAudioUrl = () => {
  const getData = useCallback(async (summaryId: string, voice: string, expanded: boolean) => {
    return await documentDb.paragraphSummaryTTS
      .where("id")
      .equals(`${summaryId}-${voice}-${expanded ? "expanded" : "base"}`)
      .first();
  }, []);

  const setCache = useCallback((summaryId: string, voice: string, expanded: boolean, blob: Blob) => {
    void documentDb.paragraphSummaryTTS.add({
      id: `${summaryId}-${voice}-${expanded ? "expanded" : "base"}`,
      fileData: blob,
    });
  }, []);

  return { setCache, getData };
};
