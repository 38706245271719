import { type ElementRef, type SVGProps } from "react";

const EmptyChatImage = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg {...props} fill="none" height="101" viewBox="0 0 121 101" width="121" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56.5 7.29445L56.4683 4.41064C56.4683 4.41064 79.9502 11.3109 93.8753 13.0642C107.801 14.8129 75.7311 15.7456 75.7311 15.7456L56.5 7.29445Z"
        fill="#E2D7F4"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M43.6652 76.7816C40.72 75.7253 37.7702 74.6684 34.825 73.6122C45.1945 56.5293 52.5411 31.702 54.9844 11.8425C55.1192 10.7324 55.3593 9.44357 56.3513 8.93559C56.9529 8.62587 57.6694 8.70768 58.337 8.79737C60.9046 9.13752 63.4681 9.47231 66.0357 9.81246"
        fill="#C5B0E8"
      />
      <path
        d="M43.6652 76.7816C40.72 75.7253 37.7702 74.6684 34.825 73.6122C45.1945 56.5293 52.5411 31.702 54.9844 11.8425C55.1192 10.7324 55.3593 9.44357 56.3513 8.93559C56.9529 8.62587 57.6694 8.70768 58.337 8.79737C60.9046 9.13752 63.4681 9.47231 66.0357 9.81246"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M110.218 17.9659C113.278 18.154 116.343 18.3427 119.404 18.526C115.756 27.4687 112.108 36.4114 108.464 45.3547"
        fill="#C5B0E8"
      />
      <path
        d="M110.218 17.9659C113.278 18.154 116.343 18.3427 119.404 18.526C115.756 27.4687 112.108 36.4114 108.464 45.3547"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M114.174 17.8233C112.532 44.5293 110.89 71.2353 109.248 97.9412C105.928 97.1243 102.607 96.3073 99.287 95.4904"
        fill="#E2D7F4"
      />
      <path
        d="M114.174 17.8233C112.532 44.5293 110.89 71.2353 109.248 97.9412C105.928 97.1243 102.607 96.3073 99.287 95.4904"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M53.9501 6.64504C52.0072 33.8194 46.9397 62.2503 37.0046 88.0184C59.3782 90.2778 81.6288 94.2019 103.443 99.7341C108.373 71.646 112.254 43.3688 115.077 14.9639C94.5705 13.3071 72.8361 11.3263 53.9494 6.64978L53.9501 6.64504Z"
        fill="white"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M82.5382 82.2616L80.3919 88.662L97.2358 93.4977L99.5067 80.4253L91.5523 82.1864L91.5902 78.0753L83.1643 79.5159L82.5382 82.2616Z"
        fill="#E2D7F4"
      />
      <path
        d="M86.8814 12.0909L86.9695 11.5407C87.1865 10.2056 86.2905 8.95143 84.979 8.75601C83.7045 8.56556 82.5074 9.44717 82.2815 10.7427L80.4388 21.3376C80.2059 22.6851 81.0203 23.9859 82.3214 24.3288C83.7317 24.7009 85.1665 23.8032 85.4932 22.353L86.6567 17.1289C86.8242 16.3732 86.3643 15.6244 85.6242 15.4529C84.8749 15.2802 84.1273 15.761 83.9586 16.5262L82.7595 22.0146"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M52.2823 58.3924C50.7138 66.423 48.6217 74.3494 46.0299 82.1024C63.4266 84.3238 80.6065 88.231 97.252 93.7572C98.4047 84.3568 100.412 75.0616 103.243 66.0268C86.1734 65.2647 68.9028 62.3549 52.2829 58.3876"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M57.9512 48.02C57.3496 48.9517 58.0272 50.2842 59.0369 50.7216C60.0466 51.1591 61.2017 50.9443 62.2617 50.6486C63.9433 50.1687 65.5743 49.4921 67.0961 48.6255C67.5445 48.3694 68.0587 48.0879 68.5489 48.2366C69.0858 48.3963 69.3598 49.0076 69.4337 49.5676C69.5021 50.0977 69.4572 50.6466 69.5599 51.1716C69.6627 51.6966 69.9659 52.2338 70.4737 52.3946C70.991 52.5566 71.539 52.2748 71.9809 51.9593C72.5033 51.5817 72.982 51.1348 73.559 50.8521C74.1361 50.5694 74.8608 50.4769 75.3956 50.8311C76.1567 51.3277 76.2352 52.4628 76.9033 53.0783C77.7941 53.899 79.2423 53.4364 80.2152 52.7152C81.188 51.9939 82.0836 51.0238 83.2802 50.8584C84.354 50.7106 85.4274 51.2832 86.1562 52.0919C86.885 52.9006 87.3351 53.9201 87.7778 54.924C89.5757 53.0771 92.2386 52.1351 94.7812 52.4475"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M58.0923 38.2828C59.0413 37.1193 60.9039 36.7729 62.2459 37.5125C62.9839 37.9193 63.5382 38.5857 64.2512 39.0357C65.6026 39.8838 67.3638 39.8268 68.9051 39.4047C70.4464 38.9825 71.876 38.2471 73.4074 37.791C74.9388 37.335 76.6774 37.1957 78.0902 37.9448C79.1797 38.5247 79.9263 39.5478 80.8849 40.3151C82.295 41.4414 84.1823 41.9839 85.9833 41.7738C88.1896 41.5156 90.4304 40.2134 92.4975 41.0271C93.675 41.4883 94.488 42.5529 95.5677 43.206C97.2917 44.2486 99.6935 44.0726 101.221 42.791"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M59.4542 28.1474C60.2675 27.2886 61.1229 26.4013 62.2306 26.0198C63.3383 25.6383 64.7685 25.9521 65.2748 27.0223C65.5314 27.5677 65.513 28.2025 65.6592 28.7865C65.8054 29.3705 66.2293 29.987 66.8238 29.9744C67.2313 29.9659 67.5673 29.6705 67.868 29.3898C68.5999 28.7097 69.3425 28.0213 69.8636 27.167C70.3848 26.3126 70.6789 25.2623 70.4218 24.2937C70.3647 26.7818 71.8906 29.2297 74.1272 30.2308C76.3637 31.2319 79.1806 30.7249 80.9566 28.998C81.9483 30.8681 84.4641 31.7121 86.3677 30.8148C87.1738 30.4366 88.0976 29.7871 88.8458 30.2671C89.3168 30.5688 89.4544 31.1808 89.7121 31.6825C90.462 33.1455 92.3573 33.6629 93.9437 33.3117C95.5302 32.9605 96.8821 31.9355 98.1826 30.9378"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M55.5753 65.004C55.3656 65.6125 55.4376 66.3462 55.8441 66.8116C56.3004 67.3299 57.0663 67.4123 57.7113 67.196C58.3563 66.9797 58.9015 66.5189 59.4353 66.072C59.5905 66.5037 59.7457 66.9353 59.9016 67.3619C60.6584 67.6177 61.42 67.874 62.1768 68.1298C62.5848 68.2667 63.0114 68.4062 63.4353 68.3194C63.8106 68.2414 64.1331 67.9921 64.4635 67.79C64.7939 67.5879 65.1813 67.4192 65.5475 67.5146C65.9138 67.61 66.1952 68.0894 66.0008 68.4433C67.0801 67.9567 68.3383 67.9717 69.3878 68.4824C70.0461 68.8019 70.6593 69.3157 71.3869 69.3312C72.1616 69.348 72.862 68.79 73.6258 68.641C74.735 68.4255 75.9156 69.1951 76.2386 70.3528C76.491 70.2019 76.7474 70.0566 76.9998 69.9057"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M55.1559 71.6362C55.1272 72.2167 55.6333 72.6706 56.1468 72.7396C56.6603 72.8086 57.1755 72.6077 57.6732 72.4265C58.171 72.2453 58.702 72.0741 59.2052 72.2189C59.9892 72.44 60.4577 73.3574 61.2119 73.6903C61.799 73.9455 62.4731 73.8101 63.0934 73.6343C63.7137 73.4586 64.3503 73.2354 64.9785 73.3308C65.6068 73.4263 66.2253 73.9614 66.2242 74.6669C66.9454 74.1078 67.997 74.1719 68.6204 74.8179"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M83.8701 64.6892C84.8635 72.562 83.4077 81.3275 80.392 88.6616"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M83.4043 79.6558C86.0975 78.8661 88.8644 78.3411 91.6476 78.083C91.6204 79.4514 91.5886 80.8191 91.5613 82.1875C94.1229 81.6007 96.6885 81.0192 99.25 80.4324"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M89.7525 68.7075C88.655 69.3022 88.5801 70.8955 88.938 71.9474C89.2959 72.9994 90.1736 73.8262 91.1984 74.2731C92.3144 74.7561 93.7747 74.7477 94.5815 73.8523C94.9822 73.4066 95.1701 72.7943 95.1979 72.1938C95.2562 70.9028 94.5764 69.603 93.4805 68.8991C92.3846 68.1953 90.9114 68.121 89.7478 68.7069"
        fill="#6D39C6"
      />
      <path
        d="M61.0468 29.9437C60.1038 13.4477 45.5384 -0.383232 28.857 1.74884C11.981 3.90542 0.059932 13.0341 1.4551 30.8949C2.5639 45.0918 12.4789 54.5759 28.3153 56.3021C45.7592 58.2044 62.09 48.1889 61.0468 29.9437Z"
        fill="#627BF1"
        stroke="#3340CB"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.2"
      />
      <path
        d="M22.5942 18.9108L28.1271 21.745C30.1369 18.1334 33.2042 18.7965 35.0607 19.8231C38.0468 21.2261 37.5799 25.8999 33.6065 27.11C27.4406 28.9889 25.7482 32.209 25.0285 33.805L30.2642 35.7813C32.1061 31.9786 38.475 32.2652 41.1565 27.4809C42.6016 24.8996 43.8953 18.5755 37.5743 15.1643C31.2534 11.7531 25.763 14.0141 22.5942 18.9126V18.9108Z"
        fill="white"
      />
      <path
        d="M25.8442 43.7813C27.6392 43.7813 29.0942 42.3262 29.0942 40.5313C29.0942 38.7363 27.6392 37.2812 25.8442 37.2812C24.0493 37.2812 22.5942 38.7363 22.5942 40.5313C22.5942 42.3262 24.0493 43.7813 25.8442 43.7813Z"
        fill="white"
      />
      <path
        d="M50.2674 45.8945C49.9744 51.793 51.451 56.379 59.1977 62.8319C59.1977 62.8319 43.6024 61.9565 39.1998 50.7323"
        fill="#627BF1"
      />
      <path
        d="M50.2674 45.8945C49.9744 51.793 51.451 56.379 59.1977 62.8319C59.1977 62.8319 43.6024 61.9565 39.1998 50.7323"
        stroke="#3340CB"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default EmptyChatImage;
